import axios from 'axios'

export default {
    namespaced: true,

    state: {
        modes: [],
    },

    getters: {
        modes (state) {
            return state.modes
        },
    },

    mutations: {
        setModes (state, modes) {
            state.modes = modes
        }
    },

    actions: {
        async getModes ({ commit }) {
            try {
                const response = await axios.get('modes')
                commit('setModes', response.data.modes)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les modes.'
                }
            }
        }
    },
}
