<template>
    <div class="form-wrapper">
        <div class="container">
            <h2 class="md-title">3. Simulation</h2>
            <form novalidate>
                <md-field>
                    <label>Niveau de départ</label>
                    <md-select v-model="form.startingLevel">
                        <md-option
                            v-for="trainingLevel in trainingLevels"
                            :key="trainingLevel.id"
                            :value="trainingLevel.id"
                        >
                            {{ trainingLevel.level }}
                        </md-option>
                    </md-select>
                </md-field>
                <md-field>
                    <label>Objectif visé</label>
                    <md-select v-model="form.endLevel">
                        <md-option
                            v-for="trainingLevel in trainingLevels"
                            :key="trainingLevel.id"
                            :value="trainingLevel.id"
                        >
                            {{ trainingLevel.level }}
                        </md-option>
                    </md-select>
                </md-field>
                <div class="pr-date">
                    <div class="date">
                        <p>Debut</p>
                        <md-datepicker
                            v-model="form.startDate"
                            md-immediately
                        />
                    </div>
                    <div class="date">
                        <p>Fin</p>
                        <md-datepicker
                            v-model="form.endDate"
                            md-immediately
                        />
                    </div>
                </div>
                <div class="actions-wrapper">
                    <md-button
                        class="md-dense md-raised md-primary"
                        @click="generateSimulation()"
                    >
                        Générer la simulation
                    </md-button>
                    <md-button
                        v-if="isGenerated"
                        class="md-dense md-primary"
                        @click="$router.push({ name: 'SimulationList' })"
                    >
                        Retour à la liste des simulations
                    </md-button>
                </div>
                <div v-if="isGenerated">
                    <div class="calendar-heading">
                        <div
                            class="arrow"
                            @click="getPreviousYear()"
                        >
                            <span class="material-icons arrow-left">
                                arrow_back_ios
                            </span>
                        </div>
                        <div class="calendar-title">Calendrier {{ currentYear }}</div>
                        <div
                            class="arrow"
                            @click="getNextYear()"
                        >
                            <span class="material-icons arrow-right">
                                arrow_forward_ios
                            </span>
                        </div>
                    </div>
                    <md-table class="calendar">
                        <md-table-row>
                            <md-table-head></md-table-head>
                            <md-table-head
                                v-for="(monthNumber, index) in 12"
                                :key="index"
                            >
                                {{ getMonth(index) }}
                            </md-table-head>
                        </md-table-row>
                        <md-table-row
                            v-for="(mode, index) in simulationForm.modes"
                            :key="index"
                        >
                            <md-table-cell>
                                <span>{{ mode.name }}</span>
                            </md-table-cell>
                            <md-table-cell
                                v-for="(monthNumber, index) in 12"
                                :key="index"
                                :class="getMonthClass(index, mode)"
                                :colspan="getColspan(index, mode)"
                            >
                                <input
                                    v-if="mode.id === 1 && displayNbHours(index) !== ''"
                                    type="number"
                                    :value="displayNbHours(index)"
                                    @change="changeNbHours($event, index)"
                                >
                                <span
                                    v-else-if="mode.id === 3 && nbHoursElearning !== 0"
                                >
                                    {{ displayNbHoursElearning(index) }}
                                </span>
                                <span
                                    v-else-if="mode.id === 4 && displayNbExams(index) !== ''"
                                >
                                    {{ displayNbExams(index) }}
                                </span>
                                <span
                                    v-if="mode.id === 1 && displayNbHours(index) !== ''"
                                    class="icon-close"
                                    title="Supprimer"
                                    @click="removeTrainingMonth(index)"
                                >
                                    <md-icon>close</md-icon>
                                </span>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                    <div class="budget">
                        <md-field>
                            <label for="">Budget</label>
                            <md-input
                                v-model="budget"
                                type="number"
                                @change="changeBudget"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="progress">
                        <h3>Progression</h3>
                        <div
                            class="divdot"
                            v-for="trainingLevel in trainingLevels"
                            :key="trainingLevel.id"
                        >
                            <span
                                class="dot"
                                :class="displayLevelProgressClass(trainingLevel)"
                            ></span>
                            <p
                                class="dotname"
                                :title="trainingLevel.level"
                            >
                                {{ trainingLevel.level }}
                            </p>
                        </div>
                    </div>
                    <div class="blocks">
                        <h3>Heures calculées</h3>
                        <div class="blockd">
                            <p>Individuel / Intra</p>
                            <div class="individual-bgcolor">Total : {{ form.totalHourQuantity }} heure{{ form.totalHourQuantity > 1 ? 's' : '' }}</div>
                        </div>
                        <div class="blockd">
                            <p>E-learning</p>
                            <div class="elearning-bgcolor">{{ nbHoursElearning }} heure{{ nbHoursElearning > 1 ? 's' : '' }} / semaine</div>
                        </div>
                        <div class="blockd">
                            <p>Examen</p>
                            <div class="exam-bgcolor">{{ simulationForm.exams.length }}</div>
                        </div>
                    </div>
                    <div class="button-wrapper">
                        <md-button
                            class="md-dense md-raised md-primary"
                            @click="createSimulation()"
                        >
                            Envoyer la simulation par email
                        </md-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"

export default {
    name: 'SimulationCreateStep3',
    data() {
        return {
            form: {
                startingLevel: 0,
                endLevel: 0,
                startDate: new Date(),
                endDate: new Date(),
                totalHourQuantity: 0
            },
            isGenerated: false,
            budget: 0,
            initialBudget: 0,
            elearningBudget: 0,
            examBudget: 0,
            currentDate: new Date(),
            trainingMonths: [],
            selectedLevels: [],
            nbHoursElearning: 0,
            initialTotalHourQuantity: 0,
        };
    },
    created () {
        this.getTrainingLevels(this.simulationForm.training.id).then((response) => {
            if (response.error) {
                this.$emit('error', response.error)
            }
        })
        this.getTrainingMatrixes(this.simulationForm.training.id).then((response) => {
            if (response.error) {
                this.$emit('error', response.error)
            }
        })
    },
    watch: {
        'form.startingLevel'() {
            this.isGenerated = false
        },
        'form.endLevel'() {
            this.isGenerated = false
        },
        'form.startDate'() {
            this.isGenerated = false
        },
        'form.endDate'() {
            this.isGenerated = false
        }
    },
    computed: {
        ...mapGetters({
            trainingLevels: 'Training/levels',
            trainingMatrixes: 'Training/matrixes',
            simulationForm: 'Simulation/form'
        }),

        currentYear () {
            return this.currentDate.getFullYear()
        }
    },
    methods: {
        ...mapActions({
            getTrainingLevels: 'Training/getLevels',
            getTrainingMatrixes: 'Training/getMatrixes',
            create: 'Simulation/create'
        }),

        generateSimulation () {
            this.isGenerated = true
            this.calculate()
        },

        getMonth (monthIndex) {
            let monthDate = new Date(this.currentYear, monthIndex, 1, 0, 0, 0)
            return monthDate.toLocaleDateString('fr-FR', {month: 'long'})
        },

        getPreviousYear () {
            this.currentDate = new Date(this.currentDate.setFullYear(this.currentDate.getFullYear() - 1))
        },

        getNextYear () {
            this.currentDate = new Date(this.currentDate.setFullYear(this.currentDate.getFullYear() + 1))
        },

        calculate () {
            if (this.form.startingLevel === 0 || this.form.endLevel === 0) {
                return
            }

            let totalHourQuantity = 0
            const trainingMatrixes = this.trainingMatrixes.filter(trainingMatrix => trainingMatrix.startLevel.id >= this.form.startingLevel && trainingMatrix.endLevel.id <= this.form.endLevel)

            for (const trainingMatrix of trainingMatrixes) {
                totalHourQuantity += trainingMatrix.quantity
            }

            const monthDiff = this.monthDiff(this.form.startDate, this.form.endDate)

            const remainder = totalHourQuantity % (monthDiff + 1)
            let quotient = 0
            if (remainder !== 0) {
                quotient = (totalHourQuantity - remainder) / (monthDiff + 1);
            } else {
                quotient = totalHourQuantity / (monthDiff + 1)
            }

            let trainingMonths = []

            for (let i = this.form.startDate.getMonth(); i <= this.form.startDate.getMonth() + monthDiff; i++) {
                let isStartMonth = i === this.form.startDate.getMonth()

                trainingMonths.push({
                    monthNumber: i % 12,
                    year: Math.floor(this.form.startDate.getFullYear() + (i / 12)),
                    nbHours: isStartMonth ? remainder !== 0 ? remainder + quotient : quotient : quotient
                })
            }

            this.form.totalHourQuantity = totalHourQuantity
            this.initialTotalHourQuantity = totalHourQuantity
            this.trainingMonths = trainingMonths

            const individualMode = this.simulationForm.modes.find(mode => mode.id === 1)

            let elearningBudget = 0

            if (this.simulationForm.elearnings.length > 0) {
                this.nbHoursElearning = 1

                for (const elearning of this.simulationForm.elearnings) {
                    elearningBudget += elearning.price
                }
            }

            this.elearningBudget = elearningBudget

            let examBudget = 0

            for (const exam of this.simulationForm.exams) {
                examBudget += exam.price
            }

            this.examBudget = examBudget

            const individualBudget = totalHourQuantity * (individualMode !== undefined ? individualMode.price : 0)
            const budget = individualBudget + elearningBudget + examBudget
            this.budget = budget
            this.initialBudget = budget

            let selectedLevels = []
            let sumNbHours = 0
            const startingLevel = this.trainingLevels.find(trainingLevel => trainingLevel.id === this.form.startingLevel)

            if (this.form.startingLevel > this.trainingLevels[0].id) {
                selectedLevels.push({
                    id: startingLevel.id,
                    name: startingLevel.level,
                    sumNbHours: sumNbHours
                })
            }

            for (let i = 0; i < this.trainingLevels.length; i++) {
                const trainingLevel = this.trainingLevels[i]

                if (trainingLevel.id >= this.form.startingLevel && trainingLevel.id <= this.form.endLevel) {

                    if (this.form.startingLevel > this.trainingLevels[0].id && trainingLevel.id === startingLevel.id) {
                        continue;
                    }

                    const trainingMatrix = this.trainingMatrixes.find(trainingMatrix => trainingMatrix.endLevel.id === trainingLevel.id)

                    if (trainingMatrix !== undefined) {
                        sumNbHours += trainingMatrix.quantity
                    }

                    selectedLevels.push({
                        id: trainingLevel.id,
                        name: trainingLevel.level,
                        sumNbHours: sumNbHours
                    })
                }
            }

            this.selectedLevels = selectedLevels
        },

        monthDiff (startDate, endDate) {
            let months;
            months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
            months -= startDate.getMonth();
            months += endDate.getMonth();
            return months <= 0 ? 0 : months;
        },

        getMonthClass (monthIndex, mode) {
            const trainingMonth = this.trainingMonths.find(trainingMonth => trainingMonth.monthNumber === monthIndex && trainingMonth.year === this.currentYear)

            let monthClass = ''

            if (trainingMonth !== undefined) {
                if (mode.id === 1) {
                    monthClass = 'training-month'
                } else if (mode.id === 3) {
                    if (monthIndex === this.form.startDate.getMonth()) {
                        monthClass = 'elearning-month'
                    }
                } else {
                    if (monthIndex === this.form.endDate.getMonth()) {
                        monthClass = 'exam-month'
                    }
                }
            }

            if (mode.id === 3) {
                if (monthIndex > (11 - this.monthDiff(this.form.startDate, this.form.endDate))) {
                    monthClass = 'no-month'
                }
            }

            return monthClass
        },

        getColspan (monthIndex, mode) {
            let colspan = 0
            if (mode.id === 3 && monthIndex === this.form.startDate.getMonth()) {
                colspan = this.monthDiff(this.form.startDate, this.form.endDate) + 1
            }
            return colspan
        },

        displayNbHours (monthIndex) {
            const trainingMonth = this.trainingMonths.find(trainingMonth => trainingMonth.monthNumber === monthIndex && trainingMonth.year === this.currentYear)
            return trainingMonth !== undefined ? trainingMonth.nbHours : ''
        },

        displayNbExams (monthIndex) {
            return monthIndex === this.form.endDate.getMonth() ? this.simulationForm.exams.length : ''
        },

        changeNbHours (event, monthIndex) {
            let trainingMonth = this.trainingMonths.find(trainingMonth => trainingMonth.monthNumber === monthIndex)

            if (trainingMonth !== undefined) {
                trainingMonth.nbHours = parseInt(event.target.value)
            }

            if (trainingMonth.nbHours < 0) {
                trainingMonth.nbHours = 0
                return
            } else if (trainingMonth.nbHours > this.form.totalHourQuantity) {
                trainingMonth.nbHours = this.form.totalHourQuantity
                return
            }

            const totalHourQuantity = this.form.totalHourQuantity
            const quantityHoursToDispatch = totalHourQuantity - event.target.value
            const monthDiff = this.monthDiff(this.form.startDate, this.form.endDate) - 1

            const remainder = quantityHoursToDispatch % (monthDiff + 1)
            let quotient = 0
            if (remainder !== 0) {
                quotient = (quantityHoursToDispatch - remainder) / (monthDiff + 1);
            } else {
                quotient = quantityHoursToDispatch / (monthDiff + 1)
            }

            this.trainingMonths = this.trainingMonths.map(function (trainingMonth, index) {

                if (trainingMonth.monthNumber !== monthIndex) {
                    trainingMonth.nbHours = index === 0 ? remainder !== 0 ? remainder + quotient : quotient : quotient
                    if (trainingMonth.nbHours < 0) {
                        trainingMonth.nbHours = 0
                    }
                }
                return trainingMonth
            })

            // const monthDiff = this.monthDiff(this.form.startDate, this.form.endDate)
            // const totalHourQuantity = this.form.totalHourQuantity
            //
            // let trainingMonths = []
            // const remainder = totalHourQuantity % (monthDiff + 1)
            // let quotient = 0
            // if (remainder !== 0) {
            //     quotient = (totalHourQuantity - remainder) / (monthDiff + 1);
            // } else {
            //     quotient = totalHourQuantity / (monthDiff + 1)
            // }
            //
            // for (let i = this.form.startDate.getMonth(); i <= this.form.startDate.getMonth() + monthDiff; i++) {
            //     let isStartMonth = i === this.form.startDate.getMonth()
            //
            //     trainingMonths.push({
            //         monthNumber: i % 12,
            //         year: Math.floor(this.form.startDate.getFullYear() + (i / 12)),
            //         nbHours: isStartMonth ? remainder !== 0 ? remainder + quotient : quotient : quotient
            //     })
            // }

            // let totalHourQuantity = 0
            // for (const trainingMonth of this.trainingMonths) {
            //     totalHourQuantity += trainingMonth.nbHours
            // }
            //
            // this.form.totalHourQuantity = totalHourQuantity
            //
            // const individualMode = this.simulationForm.modes.find(mode => mode.id === 1)
            //
            // this.budget = (totalHourQuantity * (individualMode !== undefined ? individualMode.price : 0)) + this.elearningBudget + this.examBudget
        },

        displayNbHoursElearning (index) {
            if (index === this.form.startDate.getMonth()) {
                if (this.nbHoursElearning > 0) {
                    return `${this.nbHoursElearning}h / semaine`
                }
            }
            return ''
        },

        removeTrainingMonth (index) {
            this.trainingMonths = this.trainingMonths.filter(trainingMonth => trainingMonth.monthNumber !== index)

            const totalHourQuantity = this.form.totalHourQuantity
            const monthDiff = this.monthDiff(this.form.startDate, this.form.endDate) - 1

            const remainder = totalHourQuantity % (monthDiff + 1)
            let quotient = 0
            if (remainder !== 0) {
                quotient = (totalHourQuantity - remainder) / (monthDiff + 1);
            } else {
                quotient = totalHourQuantity / (monthDiff + 1)
            }

            this.trainingMonths = this.trainingMonths.map(function (trainingMonth, index) {
                trainingMonth.nbHours = index === 0 ? remainder !== 0 ? remainder + quotient : quotient : quotient
                return trainingMonth
            })

            // let totalHourQuantity = 0
            // for (const trainingMonth of this.trainingMonths) {
            //     totalHourQuantity += trainingMonth.nbHours
            // }
            //
            // this.form.totalHourQuantity = totalHourQuantity
            //
            // const individualMode = this.simulationForm.modes.find(mode => mode.id === 1)
            //
            // this.budget = (totalHourQuantity * (individualMode !== undefined ? individualMode.price : 0)) + this.elearningBudget + this.examBudget
        },

        displayLevelProgressClass (trainingLevel) {
            let levelProgressClass = ''
            const selectedLevel = this.selectedLevels.find(selectedLevel => selectedLevel.id === trainingLevel.id)

            if (trainingLevel.id < this.selectedLevels[0].id) {
                levelProgressClass = 'full-progress'
            }

            if (selectedLevel !== undefined) {
                const elearningMode = this.simulationForm.modes.find(mode => mode.id === 3)

                if (this.form.totalHourQuantity >= selectedLevel.sumNbHours) {
                    levelProgressClass = 'full-progress'
                    if (this.form.totalHourQuantity === 0 && this.form.totalHourQuantity === selectedLevel.sumNbHours) {
                        levelProgressClass = elearningMode !== undefined ? 'partial-progress' : 'no-progress'
                    }
                } else {
                    levelProgressClass = elearningMode !== undefined ? 'partial-progress' : 'no-progress'
                }
            }

            return levelProgressClass
        },
        changeBudget (e) {
            const elearningExamBudget = this.elearningBudget + this.examBudget
            const individualBudget = this.budget - elearningExamBudget

            const currentValue = parseInt(e.target.value)
            if (currentValue < elearningExamBudget) {
                this.budget = elearningExamBudget
                return
            } else if (currentValue > this.initialBudget) {
                this.budget = this.initialBudget
                return
            }

            let newBudget = individualBudget

            const individualMode = this.simulationForm.modes.find(mode => mode.id === 1)
            const currentQuantity = this.form.totalHourQuantity
            const currentBudget = currentQuantity * (individualMode !== undefined ? individualMode.price : 0)
            let newQuantity = newBudget !== 1 ? Math.floor(currentQuantity * newBudget / currentBudget) : 1

            const monthDiff = this.monthDiff(this.form.startDate, this.form.endDate)
            const nbHoursDiff = currentQuantity - newQuantity

            if (nbHoursDiff !== 0) {
                this.nbHoursElearning += nbHoursDiff
            }

            let trainingMonths = []
            const remainder = newQuantity % (monthDiff + 1)
            let quotient = 0
            if (remainder !== 0) {
                quotient = (newQuantity - remainder) / (monthDiff + 1);
            } else {
                quotient = newQuantity / (monthDiff + 1)
            }

            for (let i = this.form.startDate.getMonth(); i <= this.form.startDate.getMonth() + monthDiff; i++) {
                let isStartMonth = i === this.form.startDate.getMonth()

                trainingMonths.push({
                    monthNumber: i % 12,
                    year: Math.floor(this.form.startDate.getFullYear() + (i / 12)),
                    nbHours: isStartMonth ? remainder !== 0 ? remainder + quotient : quotient : quotient
                })
            }

            if (newQuantity > this.initialTotalHourQuantity) {
                newQuantity = this.initialTotalHourQuantity
            } else if (newQuantity < 0) {
                newQuantity = 0
            }

            this.form.totalHourQuantity = newQuantity
            this.trainingMonths = trainingMonths
        },

        createSimulation () {
            this.create({
                customerFirstname: this.simulationForm.customer.firstname,
                customerLastname: this.simulationForm.customer.lastname,
                trainingId: this.simulationForm.training.id,
                startingLevelId: this.form.startingLevel,
                endLevelId: this.form.endLevel,
                startDate: this.form.startDate,
                endDate: this.form.endDate,
                individualHours: this.form.totalHourQuantity
            }).then((response) => {
                if (response.error) {
                    this.$emit('error', response.error)
                } else {
                    this.$router.push({ name: 'SimulationList' })
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>

.calendar-heading {

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .arrow {

        cursor: pointer;

        .arrow-left, .arrow-right {
            vertical-align: middle;
            color: #448aff;
        }

        .arrow-left {
            margin-right: 2rem;
        }

        .arrow-right {
            margin-left: 2rem;
        }
    }

    .calendar-title {
        text-align: center;
        font-weight: bold;
        color: #448aff;
        text-transform: uppercase;
        font-size: 1.1rem;
    }
}

.calendar {

    margin-bottom: 4rem;

    .md-table-head {
        background-color: #448aff;
        color: #fff;
        text-align: center;
    }

    .training-month {
        background-color: #eb5a39;
        font-weight: bold;
        text-align: center;
        position: relative;

        &:hover {
            background-color: #eb5a39;

            .icon-close {
                display: block;
            }
        }

        input {
            width: 40px;
            margin: 0 auto;
            background-color: #eb5a39;
            text-align: center;
            border: none;
        }

        .icon-close {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .elearning-month, .exam-month {
        background-color: #003e82;
        color: #fff;
        text-align: center;
    }

    .no-month {
        display: none;
    }

    .md-table-row:hover {

        .md-table-cell:not(.training-month):not(.elearning-month):not(.exam-month) {
            background-color: transparent !important;
        }

        .training-month {
            background-color: #eb5a39 !important;
        }

        .elearning-month, .exam-month {
            background-color: #003e82 !important;
        }
    }
}

.individual-bgcolor {
    background-color: #eb5a39;
}

.elearning-bgcolor {
    background-color: #003e82;
}

.exam-bgcolor {
    background-color: #003e82;
}

.progress {
    position: relative;
    width: 100%;

    .divdot {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;

        .dot {
            height: 100px;
            width: 100px;
            margin-right: 10px;
            line-height: 100px;
            text-align: center;
            background-color: #bbb;
            border-radius: 50%;
            display: inline-block;

            &.full-progress {
                background-color: green !important;
            }

            &.partial-progress {
                background-color: yellow !important;
            }

            &.no-progress {
                background-color: #bbb !important;
            }
        }

        .dotname {
            margin-top: 20px;
            text-align: center;
            max-width: 100px;
            overflow: hidden;
        }
    }
}

.blocks {

    .blockd {

        display: inline-block;

        p {
            text-align: center;
            font-weight: bold;
        }

        div {
            display: inline-block;
            width: 250px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            margin-top: 10px;
            margin-right: 20px;
            color: white;
        }
    }
}

.button-wrapper {
    text-align: right;
}

</style>
