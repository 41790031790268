<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Créer un E-learning</h1>
            <form novalidate class="md-layout" @submit.prevent="createElearning">
                <md-field>
                    <label>E-Learning</label>
                    <md-input
                        v-model="form.name"
                        required
                    />
                </md-field>
                <md-field>
                    <label>Prix</label>
                    <md-input
                        v-model="form.price"
                        type="number"
                        required
                    />
                </md-field>
                <div
                    v-if="elearningAlreadyExists"
                    class="error"
                >
                    Cet E-learning existe déjà.
                </div>
                <div class="actions-wrapper">
                    <md-button
                        type="submit"
                        class="md-dense md-raised md-primary"
                        :disabled="disabled"
                    >
                        Créer
                    </md-button>
                </div>
            </form>
        </div>
        <md-dialog-alert
            :md-active.sync="success"
            md-title="E-learning créé !"
            :md-content="`L'E-learning ${form.name} a bien été créé.`"
            md-confirm-text="Ok"
            @md-closed="$router.push({ name: 'ElearningList' })"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: "ElearningCreate",
        data () {
            return {
                success: false,
                alert: false,
                disabled: false,
                error: '',
                form: {
                    name: '',
                    price: 0,
                },
                elearningAlreadyExists: false
            }
        },
        methods: {
            ...mapActions({
                create: 'Elearning/create',
                checkIfExists: 'Elearning/checkIfExists'
            }),
            createElearning () {
                this.checkIfExists({
                    name: this.form.name
                }).then((response) => {
                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {
                        this.elearningAlreadyExists = response.data.elearningAlreadyExists
                        if (!this.elearningAlreadyExists) {
                            this.disabled = true

                            this.create(this.form).then((response) => {
                                this.disabled = false

                                if (response.error) {
                                    this.error = response.error
                                    this.alert = true
                                } else {
                                    this.success = true
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
