import axios from 'axios'
import {ApiUrl} from "../config";

export default {
    namespaced: true,

    state: {
        simulations: [],
        simulation: null,
        form: {}
    },

    getters: {
        simulations (state) {
            return state.simulations
        },
        simulation (state) {
            return state.simulation
        },
        form (state) {
            return state.form
        }
    },

    mutations: {
        setSimulations (state, simulations) {
            state.simulations = simulations
        },
        setSimulation (state, simulation) {
            state.simulation = simulation
        },
        setForm (state, form) {

            if (form === null) {
                state.form = {}
                return
            }

            for (const property in form) {
                if (!state.form.hasOwnProperty(property)) {
                    state.form[property] = form[property]
                }
            }
        },
    },

    actions: {
        async getSimulations ({ commit }) {
            try {
                const response = await axios.get('simulations')
                commit('setSimulations', response.data.simulations)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les simulations.'
                }
            }
        },
        async getSimulation ({ commit }, id) {
            try {
                const response = await axios.get('simulation/' + id)
                commit('setSimulation', response.data)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer la simulation.'
                }
            }
        },
        async create (_, data) {
            try {
                return await axios.post('simulation', data)
            } catch (e) {
                return {
                    error: 'Impossible de créer la simulation.'
                }
            }
        },
        async delete (_, id) {
            try {
                return await axios.delete('simulation/' + id)
            } catch (e) {
                return {
                    error: 'Impossible de supprimer la simulation.'
                }
            }
        },
        resetForm ({ commit }) {
            commit('setForm', null)
        }
    },
}
