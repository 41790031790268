<template>
    <div class="form-wrapper">
        <div class="container">
            <h2 class="md-title">2. Matière</h2>
            <form novalidate>
                <div
                    v-if="formError"
                    class="error"
                >
                    {{ formError }}
                </div>
                <md-field>
                    <label for="training_id">Matière</label>
                    <md-select
                        name="training_id"
                        id="training_id"
                        v-model="form.training.id"
                        required
                    >
                        <md-option
                            v-for="training in trainings"
                            :key="training.id"
                            :value="training.id"
                        >
                            {{ training.name }}
                        </md-option>
                    </md-select>
                </md-field>
                <div v-if="form.training.id">
                    <div>Sélectionnez un ou plusieurs modes :</div>
                    <div
                        v-for="trainingMode in trainingModes"
                        :key="trainingMode.id"
                        style="float: left; width: 100%"
                    >
                        <md-checkbox
                            v-model="form.modes"
                            :value="trainingMode"
                        >
                            {{ trainingMode.mode.name }}
                        </md-checkbox>
                    </div>
                    <div
                        v-if="trainingElearnings.length > 0"
                        style="float: left; width: 100%"
                    >
                        <md-checkbox
                            v-model="form.modes"
                            :value="3"
                        >
                            E-learning
                        </md-checkbox>
                    </div>
                    <div v-if="form.modes.includes(3)">
                        <md-field>
                            <label>Sélectionnez un ou plusieurs E-Learnings</label>
                            <multiselect
                                v-model="form.elearnings"
                                :options="elearningsOnly"
                                :multiple="true"
                                label="name"
                                track-by="name"
                                :showLabels="false"
                                placeholder="Sélectionnez..."
                            >
                            </multiselect>
                        </md-field>
                    </div>
                    <div
                        v-if="trainingExams.length > 0"
                        style="float: left; width: 100%"
                    >
                        <md-checkbox
                            v-model="form.modes"
                            :value="4"
                        >
                            Examen
                        </md-checkbox>
                    </div>
                    <div v-if="form.modes.includes(4)">
                        <md-field>
                            <label>Sélectionnez un ou plusieurs examens</label>
                            <multiselect
                                v-model="form.exams"
                                :options="examsOnly"
                                :multiple="true"
                                label="name"
                                track-by="name"
                                :showLabels="false"
                                placeholder="Sélectionnez..."
                            >
                            </multiselect>
                        </md-field>
                    </div>
                </div>
                <div class="actions-wrapper">
                    <md-button
                        type="button"
                        class="md-dense md-raised md-primary"
                        @click="validStep2"
                    >
                        Continuer
                    </md-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex"
import Multiselect from 'vue-multiselect'

export default {
    name: 'SimulationCreateStep2',
    components: {
        Multiselect
    },
    data() {
        return {
            form: {
                training: {
                    id: ''
                },
                modes: [],
                elearnings: [],
                exams: [],
            },
            formError: ''
        };
    },
    created () {
        this.getTrainings().then((response) => {
            if (response.error) {
                this.$emit('error', response.error)
            }
        })
    },
    watch: {
        'form.training.id'(newVal, oldVal) {
            this.form.modes = []
            this.form.elearnings = []
            this.form.exams = []

            this.getTrainingModes(newVal).then((response) => {
                if (response.error) {
                    this.$emit('error', response.error)
                }
            })

            this.getTrainingElearnings(newVal).then((response) => {
                if (response.error) {
                    this.$emit('error', response.error)
                }
            })

            this.getTrainingExams(newVal).then((response) => {
                if (response.error) {
                    this.$emit('error', response.error)
                }
            })
        },
    },
    computed: {
        ...mapGetters({
            trainings: 'Training/trainings',
            trainingModes: 'Training/modes',
            trainingElearnings: 'Training/elearnings',
            trainingExams: 'Training/exams',
        }),

        elearningsOnly () {
            return this.trainingElearnings.map(function (trainingElearning) {
                return trainingElearning.elearning
            })
        },

        examsOnly () {
            return this.trainingExams.map(function (trainingExam) {
                return trainingExam.exam
            })
        }
    },
    methods: {
        ...mapMutations({
            setForm: 'Simulation/setForm'
        }),
        ...mapActions({
            getTrainings: 'Training/getTrainings',
            getTrainingModes: 'Training/getModes',
            getTrainingElearnings: 'Training/getElearnings',
            getTrainingExams: 'Training/getExams'
        }),
        validStep2 () {
            if (!this.form.training.id) {
                this.formError = 'Vous devez sélectionner une matière.'
                return;
            }

            if (this.form.modes.length === 0) {
                this.formError = 'Vous devez sélectionner au moins un mode.'
                return;
            }

            if (this.form.modes.includes(3) && this.form.elearnings.length === 0) {
                this.formError = 'Vous devez sélectionner au moins un elearning.'
                return;
            }

            if (this.form.modes.includes(4) && this.form.exams.length === 0) {
                this.formError = 'Vous devez sélectionner au moins un examen.'
                return;
            }

            this.form.modes = this.form.modes.map(function (trainingMode) {

                let mode = null

                if (typeof trainingMode === 'object') {
                    mode = {
                        ...trainingMode.mode,
                        price: trainingMode.price
                    }
                } else {
                    if (trainingMode === 3) {
                        mode = {
                            id: trainingMode,
                            name: 'E-Learning'
                        }
                    } else if (trainingMode === 4) {
                        mode = {
                            id: trainingMode,
                            name: 'Examen'
                        }
                    }
                }

                return mode
            })

            this.setForm(this.form)
            this.$emit('go-next-step', 3)
        },
    }
};
</script>
