<template>
    <div class="password-reset-send-email">
        <h1 class="md-title">
            Réinitialiser mon mot de passe
        </h1>
        <form
            class="md-layout"
            @submit.prevent="sendEmail"
        >
            <md-field md-clearable>
                <md-input type="email" required placeholder="Email" v-model="form.email" />
            </md-field>
            <div class="md-layout-item md-layout md-alignment-center-center md-size-100">
                <md-button type="submit" class="md-primary required md-raised md-dense">ENVOYER UN LIEN DE RÉINITIALISATION</md-button>
            </div>
        </form>
        <md-dialog-alert
            :md-active.sync="success"
            md-title="Lien de réinitialisation de mot de passe envoyé"
            md-content="Vous allez recevoir un email pour pouvoir réinitialiser votre mot de passe."
            md-confirm-text="Ok"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "PasswordResetSendEmail",
    data: function () {
        return {
            success: false,
            alert: false,
            error: '',
            form: {
                email: '',
            },
        };
    },
    methods: {
        ...mapActions({
            authSendResetPasswordEmail: 'Auth/sendResetPasswordEmail'
        }),

        sendEmail () {
            this.authSendResetPasswordEmail(this.form).then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.success = true
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.password-reset-send-email {
    & > * {
        width: 60%;
        margin-left: 20%;
        margin-bottom: 3rem;
    }
    form {
        .md-field {
            margin-bottom: 2rem;
        }

        .md-button {
            width: 300px;
        }
    }
}
</style>
