<template>
    <div>
        <h1>Fiche simulation</h1>
        <md-table>
            <md-table-row>
                <md-table-head>ID</md-table-head>
                <md-table-cell>{{ simulation.id }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Client</md-table-head>
                <md-table-cell>{{ simulation.client.firstName }} {{ simulation.client.lastName }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Formation</md-table-head>
                <md-table-cell>{{ simulation.training.name }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Niveau départ</md-table-head>
                <md-table-cell>{{ simulation.startingLevel.name }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Objectif visé</md-table-head>
                <md-table-cell>{{ simulation.endLevel.name }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Date de début</md-table-head>
                <md-table-cell>{{ startDate }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Date de fin</md-table-head>
                <md-table-cell>{{ endDate }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Nombre d'heures en individuel</md-table-head>
                <md-table-cell>{{ simulation.nbHours }} heure{{ simulation.nbHours > 1 ? 's' : '' }}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "SimulationShow",
        props: {
            id: {
                required: true
            }
        },
        data () {
            return {
                error: '',
                alert: false,
                dateOptions: {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }
            }
        },
        computed: {
            ...mapGetters({
                simulation: 'Simulation/simulation',
            }),

            startDate () {
                return new Date(this.simulation.startDate).toLocaleDateString('fr-FR', this.dateOptions)
            },

            endDate () {
                return new Date(this.simulation.endDate).toLocaleDateString('fr-FR', this.dateOptions)
            }
        },
        created () {
            this.getSimulation(this.id).then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                }
            })
        },
        methods: {
            ...mapActions({
                getSimulation: 'Simulation/getSimulation',
            }),
        }
    }
</script>

<style lang="scss" scoped>
    .md-table {
        width: 50%;
        margin-left: auto;
        margin-right: auto;

        .md-table-head {
            border-top: 1px solid rgba(0,0,0,0.12)
        }

        .md-table-head, .md-table-cell {
            width: 50%;
        }

        .md-table-cell:hover {
            background-color: transparent !important;
        }
    }
</style>
