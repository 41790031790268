<template>
  <div>
    <div style="text-align: center; width: 100%">
      <h1>Simulations</h1>
    </div>
    <md-table
        class="list"
        v-model="searched"
        md-sort="id"
        md-sort-order="asc"
        md-fixed-header
    >
        <md-table-toolbar>
            <div class="md-toolbar-section-start">
                <md-button
                    class="md-raised md-primary"
                    :to="{ name: 'SimulationCreate' }"
                >
                    Créer une simulation
                </md-button>
                <md-button
                    class="md-raised"
                    @click="exportCsv"
                >
                    Export
                </md-button>
            </div>
            <md-field md-clearable class="md-toolbar-section-end">
                <md-input
                    placeholder="Rechercher par client..."
                    v-model="search"
                    @input="searchOnTable"
                />
            </md-field>
        </md-table-toolbar>
        <md-table-empty-state
            md-label="Pas de simulation trouvée"
            :md-description="`Aucune simulation trouvée pour cette requête : '${search}'.`">
        </md-table-empty-state>
          <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
          >
            <md-table-cell
                md-label="ID"
                md-sort-by="id"
            >
                {{ item.id }}
            </md-table-cell>
            <md-table-cell
                md-label="Client"
                md-sort-by="customer"
            >
                {{ item.client_lastname }} {{ item.client_firstname }}
            </md-table-cell>
            <md-table-cell>
                <a class="cell-option"
                   title="Voir"
                   @click="onShow(item.id)"
                >
                    <md-icon>visibility</md-icon>
                </a>
                <a class="cell-option"
                   title="Supprimer"
                   @click="onDelete(item.id)"
                >
                    <md-icon>delete</md-icon>
                </a>
            </md-table-cell>
          </md-table-row>
    </md-table>
    <md-dialog-confirm
        :md-active.sync="simulationToDelete"
        md-title="Supprimer la simulation"
        md-content= "Voulez-vous supprimer la simulation ?"
        md-confirm-text="Oui"
        md-cancel-text="Non"
        @md-confirm="deleteSimulation()"
    />
      <md-dialog-alert
          :md-active.sync="alert"
          md-title="Une erreur est survenue"
          :md-content="error"
          md-confirm-text="Ok"
      />
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { ApiUrl } from '../config'

    export default {
        name: 'SimulationList',
        data() {
          return {
              search: '',
              searched: [],
              simulationToDelete: null,
              alert: false,
              error: '',
          };
        },
        created () {
            this.getSimulations().then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.searched = this.simulations
                }
            })
        },
        computed: {
            ...mapGetters({
                simulations: 'Simulation/simulations'
            })
        },
        methods: {
            ...mapActions({
                getSimulations: 'Simulation/getSimulations',
                delete: 'Simulation/delete'
            }),
            searchOnTable () {
                this.searched = this.searchByCustomer(this.simulations, this.search)
            },
            searchByCustomer (items, term) {
                if (term) {
                    return items.filter(item => this.toLower(item.client_lastname).includes(this.toLower(term)) || this.toLower(item.client_firstname).includes(this.toLower(term)))
                }

                return items
            },
            toLower (text) {
                return text.toString().toLowerCase()
            },
            onShow (id) {
                this.$router.push({ name: 'SimulationShow', params: { id: id } })
            },
            onDelete (id) {
                this.simulationToDelete = id
            },
            deleteSimulation () {
                this.delete(this.simulationToDelete).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getSimulations().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.simulations
                        }
                    })
                })
            },
            exportCsv () {
                window.open(ApiUrl + "/simulation/export");
            }
        }
    };
</script>
