<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Modifier une matière</h1>
            <div v-if="step === 1">
                <h2 class="md-title">1. Définir le titre de la matière</h2>
                <form novalidate>
                    <md-field>
                        <label>Matière</label>
                        <md-input
                            v-model="form.name"
                            required
                            maxlength="100"
                        />
                    </md-field>
                    <div
                        v-if="trainingAlreadyExists"
                        class="error"
                    >
                        Cette matière existe déjà.
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            v-if="form.name"
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep1"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 2">
                <h2 class="md-title">2. Définir les modes disponibles</h2>
                <form novalidate>
                    <div class="mode-checkboxes">
                        <label
                            v-for="mode in modes"
                            :key="mode.id"
                        >
                            <input
                                type="checkbox"
                                :id="mode.id"
                                @change="updateFormModes($event, mode)"
                                :checked="form.modes.find(m => m.id === mode.id)"
                            >
                                {{ mode.name }}
                        </label>
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="-dense md-primary"
                            @click="step = 1"
                        >
                            Retour
                        </md-button>
                        <md-button
                            v-if="form.modes.length > 0"
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep2"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 3">
                <h2 class="md-title">3. Définir le tarif pour chaque mode</h2>
                <form novalidate>
                    <md-field
                        v-for="mode in form.modes"
                        :key="mode.id"
                    >
                        <label>{{ mode.name }}
                            <span v-if="mode.id === 1">(Prix unitaire €HT par heure)</span>
                        </label>
                        <md-input
                            v-if="mode.id === 1"
                            type="number"
                            step="1"
                            min="1"
                            v-model="mode.price"
                        />
                        <multiselect
                            v-else-if="mode.id === 3"
                            v-model="modeElearnings"
                            :options="elearnings"
                            :multiple="true"
                            label="name"
                            track-by="name"
                            placeholder="Sélectionnez..."
                        >
                        </multiselect>
                        <multiselect
                            v-else
                            v-model="modeExams"
                            :options="exams"
                            :multiple="true"
                            label="name"
                            track-by="name"
                            :showLabels="false"
                            placeholder="Sélectionnez..."
                        >
                        </multiselect>
                    </md-field>
                    <div
                        v-if="modePriceError"
                        class="error"
                    >
                        {{ modePriceError }}
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 2"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep3"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 4">
                <h2 class="md-title">4. Définir l'intitulé des niveaux</h2>
                <form novalidate>
                    <md-field
                        v-for="(level, index) in form.levels"
                        :key="index"
                    >
                        <label>{{ level.label }}</label>
                        <md-input
                            type="text"
                            v-model="level.value"
                        />
                    </md-field>
                    <md-button
                        class="md-raised md-primary"
                        @click="insertLevel()"
                    >
                        Ajouter un niveau
                    </md-button>
                    <md-button
                        class="md-raised md-accent"
                        @click="deleteLevel()"
                        v-if="form.levels.length > 0"
                    >
                        Supprimer un niveau
                    </md-button>
                    <br/>
                    <div
                        v-if="levelNameError"
                        class="error"
                    >
                        {{ levelNameError }}
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 3"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep4"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 5">
                <h2 class="md-title">5. Définir la matrice de progression</h2>
                <form novalidate>
                    <div
                        v-for="mode in form.modes"
                        :key="mode.id"
                    >
                        <div v-if="mode.id === 1">
                            <md-table>
                                <md-table-row>
                                    <md-table-head>Niveaux</md-table-head>
                                    <md-table-head>Nombre d'heures pour {{ mode.name }}</md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="(nbHour, index) in mode.nbHours"
                                    :key="index"
                                >
                                    <md-table-cell>
                                        Du niveau {{ nbHour.startLevel }}
                                        <br />
                                        au niveau {{ nbHour.endLevel }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-field>
                                            <md-input
                                                type="number"
                                                step="1"
                                                min="1"
                                                v-model="nbHour.quantity"
                                            />
                                        </md-field>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 4"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            :disabled="disabled"
                            @click="updateTraining"
                        >
                            Modifier
                        </md-button>
                    </div>
                </form>
            </div>
            <md-dialog-alert
                :md-active.sync="success"
                md-title="Matière modifiée !"
                :md-content="`La matière ${form.name} a bien été modifiée.`"
                md-confirm-text="Ok"
                @md-closed="$router.push({ name: 'TrainingList' })"
            />
            <md-dialog-alert
                :md-active.sync="alert"
                md-title="Une erreur est survenue"
                :md-content="error"
                md-confirm-text="Ok"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Multiselect from 'vue-multiselect'

export default {
    name: 'TrainingUpdate',
    components: {
        Multiselect
    },
    props: {
        id: {
            required: true
        }
    },
    data () {
        return {
            success: false,
            alert: false,
            disabled: false,
            error: '',
            step: 1,
            form: {
                name: '',
                modes: [],
                levels: []
            },
            modeExams: [],
            modeElearnings: [],
            modePriceError: '',
            levelNameError: '',
            trainingAlreadyExists: false
        };
    },
    created () {
        this.getModes().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getExams().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getElearnings().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getTraining(this.id).then((response) => {

            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const training = response
            this.form.name = training.name
        })

        this.getTrainingModes(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const trainingModes = response.trainingModes

            if (trainingModes.length > 0) {
                for (const trainingMode of trainingModes) {
                    this.form.modes.push({
                        ...trainingMode.mode,
                        price: trainingMode.price
                    })
                }
            }
        })

        this.getTrainingLevels(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const trainingLevels = response.trainingLevels

            if (trainingLevels.length > 0) {
                for (let i = 0; i < trainingLevels.length; i++) {

                    const trainingLevel = trainingLevels[i]

                    this.form.levels.push({
                        label: (i === 0 ? `${i + 1}er` :  `${i + 1}e`) + ' niveau',
                        value: trainingLevel.level
                    })
                }
            }
        })

        this.getTrainingElearnings(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const trainingElearnings = response.trainingElearnings

            if (trainingElearnings.length > 0) {

                let elearnings = []

                for (const trainingElearning of trainingElearnings) {
                    elearnings.push(trainingElearning.elearning)
                }

                this.form.modes.push({
                    id: 3,
                    name: this.modes.find(mode => mode.id === 3).name
                })

                this.modeElearnings = elearnings
            }
        })

        this.getTrainingExams(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const trainingExams = response.trainingExams

            if (trainingExams.length > 0) {

                let exams = []

                for (const trainingExam of trainingExams) {
                    exams.push(trainingExam.exam)
                }

                this.form.modes.push({
                    id: 4,
                    name: this.modes.find(mode => mode.id === 4).name
                })

                this.modeExams = exams
            }
        })

        this.getTrainingMatrixes(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
                return
            }

            const trainingMatrixes = response.trainingMatrixes

            if (trainingMatrixes.length > 0) {

                let individualFormMode = this.form.modes.find(mode => mode.id === 1)
                individualFormMode.nbHours = []

                for (const trainingMatrix of trainingMatrixes) {

                    if (trainingMatrix.mode.id === 1) {

                        individualFormMode.nbHours.push({
                            startLevel: trainingMatrix.startLevel.level,
                            endLevel: trainingMatrix.endLevel.level,
                            quantity: trainingMatrix.quantity
                        })
                    }
                }
            }
        })
    },
    computed: {
        ...mapGetters({
            training: 'Training/training',
            trainingModes: 'Training/modes',
            trainingLevels: 'Training/levels',
            trainingElearnings: 'Training/elearnings',
            trainingExams: 'Training/exams',
            trainingMatrixes: 'Training/matrixes',
            modes: 'Mode/modes',
            exams: 'Exam/exams',
            elearnings: 'Elearning/elearnings',
        })
    },
    methods: {
        ...mapActions({
            getTraining: 'Training/getTraining',
            getTrainingModes: 'Training/getModes',
            getTrainingLevels: 'Training/getLevels',
            getTrainingElearnings: 'Training/getElearnings',
            getTrainingExams: 'Training/getExams',
            getTrainingMatrixes: 'Training/getMatrixes',
            getModes: 'Mode/getModes',
            getExams: 'Exam/getExams',
            getElearnings: 'Elearning/getElearnings',
            update: 'Training/update',
            checkIfExists: 'Training/checkIfExists'
        }),
        validStep1 () {
            if (this.training.name !== this.form.name) {
                this.checkIfExists({
                    name: this.form.name
                }).then((response) => {
                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {
                        this.trainingAlreadyExists = response.data.trainingAlreadyExists
                        if (!this.trainingAlreadyExists) {
                            this.step++
                        }
                    }
                })
            } else {
                this.step++
            }
        },
        updateFormModes ($event, mode) {
            if ($event.target.checked) {
                this.form.modes.push(mode)
                if (mode.id === 1) {
                    this.form.levels.push({
                        label: '1er niveau',
                        value: ''
                    })
                }
            } else {
                this.form.modes = this.form.modes.filter(m => m.id !== mode.id)

                if (mode.id === 1) {
                    this.form.levels = []
                    mode.price = ''
                    mode.nbHours = []
                } else if (mode.id === 3) {
                    this.modeElearnings = []
                } else if (mode.id === 4) {
                    this.modeExams = []
                }
            }
        },
        validStep2 () {
            this.form.modes = this.form.modes.map(function (mode) {

                if (mode.id === 1) {
                    if (!mode.hasOwnProperty('price')) {
                        mode.price = ''
                    }
                } else if (mode.id === 3) {
                    if (!mode.hasOwnProperty('elearnings')) {
                        mode.elearnings = []
                    }
                } else if (mode.id === 4) {
                    if (!mode.hasOwnProperty('exams')) {
                        mode.exams = []
                    }
                }

                return mode
            })
            this.step++
        },
        validStep3 () {
            this.modePriceError = ''

            const individualMode = this.form.modes.find(mode => mode.id === 1)
            const elearningMode = this.form.modes.find(mode => mode.id === 3)
            const examMode = this.form.modes.find(mode => mode.id === 4)
            let nbErrors = 0

            if (individualMode !== undefined) {
                if (individualMode.price === '') {
                    nbErrors++
                }
            }

            if (elearningMode !== undefined) {
                if (this.modeElearnings.length === 0) {
                    nbErrors++
                }
            }

            if (examMode !== undefined) {
                if (this.modeExams.length === 0) {
                    nbErrors++
                }
            }

            if (nbErrors > 0) {
                this.modePriceError = 'Vous devez renseigner tous les champs.'
                return
            }

            const modeExams = this.modeExams
            const modeElearnings = this.modeElearnings

            this.form.modes = this.form.modes.map(function (mode) {

                if (mode.id === 3) {
                    mode.elearnings = modeElearnings
                } else if (mode.id === 4) {
                    mode.exams = modeExams
                }

                return mode
            })

            if (individualMode === undefined) {
                this.updateTraining()
            } else {
                this.step++
            }
        },
        insertLevel () {
            this.form.levels.push({
                label: this.form.levels.length + 1 + 'e niveau',
                value: ''
            })
        },
        deleteLevel () {
            this.form.levels.pop()
            let individualMode = this.form.modes.find(mode => mode.id === 1)
            individualMode.nbHours.pop()
        },
        validStep4 () {
            this.levelNameError = ''
            let nbErrors = 0

            for (const level of this.form.levels) {
                if (level.value === '') {
                    nbErrors++
                }
            }

            if (nbErrors > 0) {
                this.levelNameError = 'Vous devez renseigner tous les champs.'
                return
            }

            const formLevels = this.form.levels

            this.form.modes = this.form.modes.map(function (mode) {

                if (mode.id === 1) {
                    if (!mode.hasOwnProperty('nbHours')) {
                        mode.nbHours = []
                    }

                    if (mode.nbHours.length === 0) {
                        for (let i = 0; i < formLevels.length; i++) {
                            if (formLevels[i + 1] !== undefined) {
                                const level = formLevels[i]

                                mode.nbHours.push({
                                    startLevel: level.value,
                                    endLevel: formLevels[i + 1].value,
                                    quantity: 0
                                })
                            }
                        }
                    }
                }

                return mode
            })
            this.step++
        },
        updateTraining () {
            this.disabled = true

            this.update({ id: this.id, data: this.form }).then((response) => {
                this.disabled = false

                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.success = true
                }
            })
        }
    }
};
</script>

<style lang="scss">
.md-checkbox {
    display: flex;
}

.error {
    color: #ff5252;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after,
.multiselect__tag,
.multiselect__tag-icon::after,
.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background-color: #448aff !important;
}

.multiselect__tag-icon::after {
    color: #fff !important;
}

.mode-checkboxes {
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 1rem
    }
}
</style>
