import axios from 'axios'

export default {
    namespaced: true,

    state: {
        users: [],
        user: null
    },

    getters: {
        users (state) {
            return state.users
        },
        user (state) {
            return state.user
        }
    },

    mutations: {
        setUsers (state, users) {
            state.users = users
        },
        setUser (state, user) {
            state.user = user
        }
    },

    actions: {
        async getUsers ({ commit }) {
            try {
                const response = await axios.get('users')
                commit('setUsers', response.data.users)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les utilisateurs.'
                }
            }
        },
        async getUser ({ commit }, id) {
            try {
                const response = await axios.get('user/' + id)
                commit('setUser', response.data)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer l\'utilisateur.'
                }
            }
        },
        async create (_, data) {
            try {
                return await axios.post('user', data)
            } catch (e) {
                return {
                    error: 'Impossible de créer l\'utilisateur.'
                }
            }
        },
        async update (_, { id, data }) {
            try {
                return await axios.put('user/' + id, data)
            } catch (e) {
                return {
                    error: 'Impossible de modifier l\'utilisateur.'
                }
            }
        },
        async delete (_, id) {
            try {
                return await axios.delete('user/' + id)
            } catch (e) {
                return {
                    error: 'Impossible de supprimer l\'utilisateur.'
                }
            }
        },
        async disable (_, id) {
            try {
                return await axios.put('user/' + id + '/disable')
            } catch (e) {
                return {
                    error: 'Impossible de désactiver l\'utilisateur.'
                }
            }
        },
        async enable (_, id) {
            try {
                return await axios.put('user/' + id + '/enable')
            } catch (e) {
                return {
                    error: 'Impossible d\'activer l\'utilisateur.'
                }
            }
        },
        async checkIfExists (_, data) {
            try {
                return await axios.post('user/check-if-exists', data)
            } catch (e) {
                return {
                    error: 'Impossible de savoir si l\'utilisateur existe déjà.'
                }
            }
        }
    },
}
