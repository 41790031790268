import axios from 'axios'

export default {
    namespaced: true,

    state: {
        exams: [],
        exam: null
    },

    getters: {
        exams (state) {
            return state.exams
        },
        exam (state) {
            return state.exam
        },
    },

    mutations: {
        setExams (state, exams) {
            state.exams = exams
        },
        setExam (state, exam) {
            state.exam = exam
        }
    },

    actions: {
        async getExams ({ commit }) {
            try {
                const response = await axios.get('exams')
                commit('setExams', response.data.exams)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les examens.'
                }
            }
        },
        async getExam ({ commit }, id) {
            try {
                const response = await axios.get('exam/' + id)
                commit('setExam', response.data)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer l\'examen.'
                }
            }
        },
        async create (_, data) {
            try {
                return await axios.post('exam', data)
            } catch (e) {
                return {
                    error: 'Impossible de créer l\'examen.'
                }
            }
        },
        async update (_, { id, data }) {
            try {
                return await axios.put('exam/' + id, data)
            } catch (e) {
                return {
                    error: 'Impossible de modifier l\'examen.'
                }
            }
        },
        async delete (_, id) {
            try {
                return await axios.delete('exam/' + id)
            } catch (e) {
                return {
                    error: 'Impossible de supprimer l\'examen.'
                }
            }
        },
        async copy (_, data) {
            try {
                return await axios.post('exam/copy', data)
            } catch (e) {
                return {
                    error: 'Impossible de copier l\'examen.'
                }
            }
        },
        async checkIfExists (_, data) {
            try {
                return await axios.post('exam/check-if-exists', data)
            } catch (e) {
                return {
                    error: 'Impossible de savoir si l\'examen existe déjà.'
                }
            }
        }
    },
}
