<template>
  <div class="page-container">
    <md-app md-waterfall md-mode="fixed-last">
      <md-app-toolbar class="md-large md-dense md-primary">
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start logo-wrapper">
            <router-link to="/">
              <img src="../public/logo.png" alt="" width="280" height="auto">
            </router-link>
          </div>
            <template v-if="authenticated">
                <div class="user-auth">{{ authUser.firstname }} {{ authUser.lastname }}</div>
                  <md-menu md-size="small" md-align-trigger>
                    <md-button md-menu-trigger class="md-icon-button">
                      <md-icon style="color: #003e82">more_vert</md-icon>
                    </md-button>
                    <md-menu-content>
                      <md-menu-item @click="logout">Déconnexion</md-menu-item>
                    </md-menu-content>
                  </md-menu>
            </template>
        </div>
        <div class="md-toolbar-row tbrb">
          <md-menu md-direction="bottom-start" v-if="authenticated">
            <md-button :to="{ name: 'SimulationList' }">Simulations</md-button>
              <md-button v-if='authUser.role == 4' :to="{ name: 'ExamList' }">Examens</md-button>
              <md-button v-if='authUser.role == 4' :to="{ name: 'ElearningList' }">E-Learning</md-button>
            <md-button v-if='authUser.role == 4' :to="{ name: 'TrainingList' }">Matières</md-button>
            <md-button v-if='authUser.role == 4' :to="{ name: 'UserList' }">Utilisateurs</md-button>
          </md-menu>
        </div>
      </md-app-toolbar>
      <md-app-content>
        <router-view></router-view>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
      name: 'App',
      computed: {
          ...mapGetters({
              authenticated: 'Auth/authenticated',
              authUser: 'Auth/user',
          })
      },
      methods: {
          ...mapActions({
              authLogout: 'Auth/logout'
          }),

          logout () {
              this.authLogout().then(() => {
                  this.$router.push({ name: 'Login' })
              })
          }
      },
  }
</script>

<style lang="scss" scoped>

    :root {
        --md-theme-default-primary:#003e82!important;
    }

    .md-toolbar.md-theme-default.md-primary {
        background-color: white!important;
    }

    .md-app {
        min-height: 100vh;
        border: 1px solid rgba(#000, .12);
    }

    .md-app-drawer {
        width: 300px;
        max-width: calc(100vw - 125px);
    }
    .md-app-content {
        width: 100%;
        padding-top: 6rem;
    }

    .tbrb {
        background-color: #003e82!important;
        padding: 0.5rem;
    }

    .md-button {
        margin-right: 1rem;
    }

    .user-auth {
        color: rgba(0,0,0,0.87);
    }

    .logo-wrapper {
        padding: 1rem 0;
    }

    @media only screen and (max-width: 600px) {
        .md-toolbar-row {
            .md-tabs {
                display: none;
            }
            img {
                max-width: 200px!important;
                max-height: 100px!important;
            }
        }
    }
</style>

<style lang="scss">
h1 {
    color: #003e82;
    font-size: 40px;
    font-weight: 700;
    margin: 0px auto 36px auto;
    padding: 0;
    width: fit-content;
}

.md-table.list {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    .md-table-cell {
        cursor: pointer;
    }

    .md-table-cell:last-child .md-table-cell-container {
        display: flex !important;
    }

    .cell-option:not(:last-of-type) {
        margin-right: 1rem;
    }
}

.form-wrapper {

    .md-primary {
        margin-bottom: 1rem;
    }

    .md-dialog {
        padding: 10px;
    }

    .container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .actions-wrapper {
        width: 100%;
        text-align: right;
    }
}

.error {
    color: #ff5252;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
