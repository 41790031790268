<template>
    <div>
        <div style="text-align: center; width: 100%">
            <h1>Utilisateurs</h1>
        </div>
        <md-table
            class="list"
            v-model="searched"
            md-sort="id"
            md-sort-order="asc"
            md-fixed-header
        >
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <md-button
                        class="md-raised md-primary"
                        :to="{ name: 'UserCreate' }"
                    >
                        Créer un utilisateur
                    </md-button>
                </div>
                <md-field md-clearable class="md-toolbar-section-end">
                    <md-input
                        placeholder="Rechercher par nom ou prénom..."
                        v-model="search"
                        @input="searchOnTable"
                    />
                </md-field>
            </md-table-toolbar>
            <md-table-empty-state
                md-label="Pas d'utilisateur trouvé"
                :md-description="`Aucun utilisateur trouvé pour cette requête : '${search}'.`">
            </md-table-empty-state>
            <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
            >
                <md-table-cell
                    md-label="ID"
                    md-sort-by="id"
                >
                    {{ item.id }}
                </md-table-cell>
                <md-table-cell
                    md-label="Nom & Prénom"
                    md-sort-by="fullname"
                >
                    {{ item.lastname }} {{ item.firstname }}
                </md-table-cell>
                <md-table-cell
                    md-label="Email"
                    md-sort-by="email"
                >
                    {{ item.email }}
                </md-table-cell>
                <md-table-cell
                    md-label="Rôle"
                    md-sort-by="role"
                >
                    {{ roles.filter(r => r.id == item.role)[0].name }}
                </md-table-cell>
                <md-table-cell>
                    <a class="cell-option"
                       title="Modifier"
                       @click="onUpdate(item.id)"
                    >
                        <md-icon>edit</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Supprimer"
                       @click="onDelete(item.id)"
                    >
                        <md-icon>delete</md-icon>
                    </a>
                    <a
                       v-if="!item.is_disabled"
                       class="cell-option"
                       title="Désactiver"
                       @click="onDisable(item.id)"
                    >
                        <md-icon>person_off</md-icon>
                    </a>
                    <a
                       v-else
                       class="cell-option"
                       title="Activer"
                       @click="onEnable(item.id)"
                    >
                        <md-icon>person</md-icon>
                    </a>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <md-dialog-confirm
            :md-active.sync="userToDelete"
            md-title="Supprimer le compte"
            md-content="Voulez-vous supprimer ce compte ?"
            md-confirm-text="Oui"
            md-cancel-text="Non"
            @md-confirm="deleteUser()"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: 'UserList',
        data () {
            return {
                search: '',
                searched: [],
                userToDelete: null,
                alert: false,
                error: '',
                roles: [
                    {
                        id: 1,
                        name: "Utilisateur",
                    },
                    {
                        id: 2,
                        name: "Manager",
                    },
                    {
                        id: 3,
                        name: "Commercial",
                    },
                    {
                        id: 4,
                        name: "Administrateur",
                    },
                ],
            }
        },
        created () {
            this.getUsers().then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.searched = this.users
                }
            })
        },
        computed: {
            ...mapGetters({
                users: 'User/users',
                authUser: 'Auth/user',
            })
        },
        methods: {
            ...mapActions({
                getUsers: 'User/getUsers',
                delete: 'User/delete',
                disable: 'User/disable',
                enable: 'User/enable'
            }),
            searchOnTable () {
                this.searched = this.searchByFullname(this.users, this.search)
            },
            searchByFullname (items, term) {
                if (term) {
                    return items.filter(item => this.toLower(item.lastname).includes(this.toLower(term)) || this.toLower(item.firstname).includes(this.toLower(term)))
                }

                return items
            },
            toLower (text) {
                return text.toString().toLowerCase()
            },
            onUpdate (id) {
                this.$router.push({ name: 'UserUpdate', params: { id: id } })
            },
            onDelete (id) {
                this.userToDelete = id
            },
            onDisable (id) {
                this.disable(id).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getUsers().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.users
                        }
                    })
                })
            },
            onEnable (id) {
                this.enable(id).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getUsers().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.users
                        }
                    })
                })
            },
            deleteUser () {
                this.delete(this.userToDelete).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getUsers().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.users
                        }
                    })
                })
            }
        }
    }
</script>
