<template>
    <div>
        <div style="text-align: center; width: 100%">
            <h1>Matières</h1>
        </div>
        <md-table
            class="list"
            v-model="searched"
            md-sort="id"
            md-sort-order="asc"
            md-fixed-header
        >
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <md-button
                        class="md-raised md-primary"
                        :to="{ name: 'TrainingCreate' }"
                    >
                        Créer une matière
                    </md-button>
                </div>
                <md-field md-clearable class="md-toolbar-section-end">
                    <md-input
                        placeholder="Rechercher par intitulé..."
                        v-model="search"
                        @input="searchOnTable"
                    />
                </md-field>
            </md-table-toolbar>
            <md-table-empty-state
                md-label="Pas de matière trouvée"
                :md-description="`Aucune matière trouvée pour cette requête : '${search}'.`">
            </md-table-empty-state>
            <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
            >
                <md-table-cell
                    md-label="ID"
                    md-sort-by="id"
                >
                    {{ item.id }}
                </md-table-cell>
                <md-table-cell
                    md-label="Matière"
                    md-sort-by="training"
                >
                    {{ item.name }}
                </md-table-cell>
                <md-table-cell>
                    <a class="cell-option"
                       title="Voir"
                       @click="onShow(item.id)"
                    >
                        <md-icon>visibility</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Modifier"
                       @click="onUpdate(item.id)"
                    >
                        <md-icon>edit</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Copier"
                       @click="onCopy(item.id)"
                    >
                        <md-icon>file_copy</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Supprimer"
                       @click="onDelete(item.id)"
                    >
                        <md-icon>delete</md-icon>
                    </a>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <md-dialog-confirm
            :md-active.sync="trainingToDelete"
            md-title="Supprimer la matière"
            md-content="Voulez-vous supprimer cette matière ?"
            md-confirm-text="Oui"
            md-cancel-text="Non"
            @md-confirm="deleteTraining"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
        <md-dialog-prompt
            :md-active.sync="trainingToCopy"
            v-model="nbCopy"
            md-title="Copier la matière"
            md-input-placeholder="Indiquez le nombre de copies..."
            md-confirm-text="Copier"
            md-cancel-text="Annuler"
            @md-confirm="copyTraining"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "TrainingList",
    data () {
        return {
            nbCopy: 0,
            search: '',
            searched: [],
            trainingToDelete: null,
            trainingToCopy: null,
            alert: false,
            error: '',
        }
    },
    created () {
        this.getTrainings().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            } else {
                this.searched = this.trainings
            }
        })
    },
    computed: {
        ...mapGetters({
            trainings: 'Training/trainings'
        })
    },
    methods: {
        ...mapActions({
            getTrainings: 'Training/getTrainings',
            delete: 'Training/delete',
            copy: 'Training/copy'
        }),
        searchOnTable () {
            this.searched = this.searchByName(this.trainings, this.search)
        },
        searchByName (items, term) {
            if (term) {
                return items.filter(item => this.toLower(item.name).includes(this.toLower(term)))
            }

            return items
        },
        toLower (text) {
            return text.toString().toLowerCase()
        },
        onShow (id) {
            this.$router.push({ name: 'TrainingShow', params: { id: id } })
        },
        onUpdate (id) {
            this.$router.push({ name: 'TrainingUpdate', params: { id: id } })
        },
        onDelete (id) {
            this.trainingToDelete = id
        },
        deleteTraining () {
            this.delete(this.trainingToDelete).then((response) => {

                if (response.error) {
                    this.error = response.error
                    this.alert = true
                    return
                }

                this.getTrainings().then((response) => {
                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {
                        this.searched = this.trainings
                    }
                })
            })
        },
        onCopy (id) {
            this.trainingToCopy = id
        },
        copyTraining () {
            this.copy({
                id: this.trainingToCopy,
                nbCopy: this.nbCopy
            }).then((response) => {

                if (response.error) {
                    this.error = response.error
                    this.alert = true
                    return
                }

                this.getTrainings().then((response) => {
                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {
                        this.searched = this.trainings
                    }
                })
            })
        }
    }
}
</script>
