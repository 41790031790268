import axios from 'axios'

export default {
    namespaced: true,

    state: {
        token: null,
        user: null,
        error: ''
    },

    getters: {
        authenticated (state) {
            return !!(state.token && state.user)
        },

        user (state) {
            return state.user
        },

        error (state) {
            return state.error;
        }
    },

    mutations: {
        setToken (state, token) {
            state.token = token
        },
        setUser (state, user) {
            state.user = user
        },
        setError(state, error) {
            state.error = error
        },
    },

    actions: {
        async login ({ commit, dispatch }, credentials) {
            await axios.post('auth/login', credentials).then((response) => {
                return dispatch('attempt', response.data.token)
            }).catch((e) => {
                commit('setError', e.response.data.error);
            })
        },

        async attempt ({ commit, state }, token) {
            if (token) {
                commit('setToken', token)
            }

            if (!state.token) {
                return
            }

            try {
                const response = await axios.get('auth/me')

                commit('setUser', response.data)
            } catch (e) {
                commit('setToken', null)
                commit('setUser', null)
            }
        },

        logout ({ commit }) {
            return axios.post('auth/logout').then(() => {
                commit('setToken', null)
                commit('setUser', null)
            })
        },

        async sendResetPasswordEmail (_, data) {
            try {
                return await axios.post('auth/password/email', data)
            } catch (e) {
                return {
                    error: 'Impossible d\'envoyer un email de réinitialisation de mot de passe.'
                }
            }
        },

        async resetPassword (_, data) {
            try {
                return await axios.post('auth/password/reset', data)
            } catch (e) {
                return {
                    error: 'Impossible de réinitialiser le mot de passe.'
                }
            }
        },
    },
}
