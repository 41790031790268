import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth'
import Simulation from './simulation'
import Training from './training'
import User from './user'
import Mode from './mode'
import Exam from "./exam"
import Elearning from "./elearning"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },

    mutations: {
    },

    actions: {
    },

    modules: {
        Auth,
        Simulation,
        Training,
        User,
        Mode,
        Exam,
        Elearning
    }
})
