<template>
    <div>
        <h1>Fiche matière</h1>
        <md-table>
            <md-table-row>
                <md-table-head>ID</md-table-head>
                <md-table-cell>{{ training.id }}</md-table-cell>
            </md-table-row>
            <md-table-row>
                <md-table-head>Nom de la matière</md-table-head>
                <md-table-cell>{{ training.name }}</md-table-cell>
            </md-table-row>
        </md-table>
        <md-table>
            <md-table-row>
                <md-table-head>Mode(s)</md-table-head>
                <md-table-head>Prix Unitaire HT / heure</md-table-head>
            </md-table-row>
            <md-table-row
                v-for="trainingMode in trainingModes"
                :key="trainingMode.id"
            >
                <md-table-cell>{{ trainingMode.mode.name }}</md-table-cell>
                <md-table-cell>{{ trainingMode.price }} €</md-table-cell>
            </md-table-row>
            <md-table-row v-if="trainingElearnings.length > 0">
                <md-table-cell>E-learning</md-table-cell>
                <md-table-cell>
                    <div
                        v-for="trainingElearning in trainingElearnings"
                        :key="trainingElearning.id"
                    >
                        {{ trainingElearning.elearning.name }} : {{ trainingElearning.elearning.price }} €
                    </div>
                </md-table-cell>
            </md-table-row>
            <md-table-row v-if="trainingExams.length > 0">
                <md-table-cell>Examens</md-table-cell>
                <md-table-cell>
                    <div
                        v-for="trainingExam in trainingExams"
                        :key="trainingExam.id"
                    >
                        {{ trainingExam.exam.name }} : {{ trainingExam.exam.price }} €
                    </div>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <md-table>
            <md-table-row>
                <md-table-head colspan="3">Matrice de progression</md-table-head>
            </md-table-row>
            <md-table-row>
                <md-table-head>Niveau(x)</md-table-head>
                <md-table-head>Nombre d'heures en individuel</md-table-head>
            </md-table-row>
            <md-table-row
                v-for="(nbHour, index) in nbHours"
                :key="index"
            >
                <md-table-cell>{{ nbHour.levelRange }}</md-table-cell>
                <md-table-cell>{{ nbHour.individual }}</md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "TrainingShow",
    props: {
        id: {
            required: true
        }
    },
    created () {
        this.getTraining(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getTrainingModes(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getTrainingElearnings(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getTrainingExams(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getTrainingMatrixes(this.id).then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })
    },
    computed: {
        ...mapGetters({
            training: 'Training/training',
            trainingModes: 'Training/modes',
            trainingElearnings: 'Training/elearnings',
            trainingExams: 'Training/exams',
            trainingMatrixes: 'Training/matrixes'
        }),

        nbHours () {
            let nbHours = []

            for (let i = 0; i < this.trainingMatrixes.length; i++) {

                const trainingMatrix = this.trainingMatrixes[i]
                let levelRange = `Du ${trainingMatrix.startLevel.level} au ${trainingMatrix.endLevel.level}`
                let nbHour

                let nbHourAlreadyExists = nbHours.find(nbHour => nbHour.levelRange === levelRange)

                if (nbHourAlreadyExists === undefined) {
                    nbHour = {}
                    nbHour.levelRange = levelRange
                } else {
                    nbHour = nbHourAlreadyExists
                }

                if (!nbHour.hasOwnProperty('individual')) {
                    nbHour.individual = 0
                }

                nbHour.individual = trainingMatrix.quantity

                if (nbHourAlreadyExists === undefined) {
                    nbHours.push(nbHour)
                }
            }

            return nbHours
        }
    },
    methods: {
        ...mapActions({
            getTraining: 'Training/getTraining',
            getTrainingModes: 'Training/getModes',
            getTrainingElearnings: 'Training/getElearnings',
            getTrainingExams: 'Training/getExams',
            getTrainingMatrixes: 'Training/getMatrixes'
        })
    }
}
</script>

<style lang="scss" scoped>

    .md-table {
        width: 50%;
        margin-left: auto;
        margin-right: auto;

        .md-table-head {
            border-top: 1px solid rgba(0,0,0,0.12)
        }

        .md-table-head, .md-table-cell {
            width: 50%;
        }

        &:first-of-type {

            .md-table-cell:hover {
                background-color: transparent !important;
            }
        }

        &:first-of-type, &:nth-of-type(2) {
            margin-bottom: 4rem;
        }

        &:last-of-type {

            .md-table-row:first-of-type {

                .md-table-head {
                    text-align: center;
                }
            }
        }
    }
</style>
