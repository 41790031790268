<template>
  <div class="admin">
    <h2>
      <router-link to="/signup">Créer un nouveau utilisateur</router-link>
    </h2>
    <h2>
      <router-link to="/client">Créer une nouvelle simulation</router-link>
    </h2>
    <h2>
      <router-link to="/simulation">Afficher toutes les simulations</router-link>
    </h2>
    <h1>Administration</h1>
    <div>
      <h2>Taux</h2>
      <table>
        <tr>
          <th>Taux</th>
          <th>Tarif</th>
          <th>Unité</th>
        </tr>
        <tr>
          <td>Taux horaire moyen</td>
          <td>
            <input type="number" :step="STEP" :min="cpf" :max="MAX" v-model="avg" />
            <button @click="changeValue('avg_price',avg)">Modifier</button>
          </td>
          <td>euros/heure</td>
        </tr>
        <tr>
          <td>Taux CPF</td>
          <td>
            <input type="number" :step="STEP" :min="MIN" :max="MAX" v-model="cpf" />
            <button @click="changeValue('cpf_price',cpf)">Modifier</button>
          </td>
          <td>euros/heure</td>
        </tr>
      </table>
    </div>
    <div>
      <h2>Tarif modalité</h2>
      <table>
        <tr>
          <th>Modalité</th>
          <th>Tarif</th>
          <th>Heure</th>
          <th>Efficacité</th>
          <th>Note</th>
        </tr>
        <tr v-for="m in mode" :value="m">
          <td>{{m.name}}</td>
          <td v-if="m.price != null">
            <input type="number" :step="STEP" :min="MIN" :max="MAX" v-model="m.price" />
            <button @click="changePrice(m.id,m.price)">Modifier</button>
          </td>
          <td v-else></td>
          <td v-if="m.hour != null">
            <input type="number" :step="STEP" :min="MIN" :max="MAX" v-model="m.hour" />
            <button @click="changeHour(m.id,m.hour)">Modifier</button>
          </td>
          <td v-else></td>
          <td v-if="m.efficiency != null">
            <input type="number" :step="STEP" :min="MIN" :max="MAX" v-model="m.efficiency" />
            <button @click="changeEfficiency(m.id,m.efficiency)">Modifier</button>
          </td>
          <td v-else></td>
          <td>
            <p style="font-size:16px;white-space:pre;">{{m.note}}</p>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <h2>Modalités par formation et durée</h2>
      <div style="text-align:center;">
        <label>Formation :</label>
        <select v-model="s_formation" @change="changeOption">
          <option v-for="f in formation" v-bind:value="f.id">{{f.name}}</option>
        </select>
        <label>Durée :</label>
        <select v-model="s_duration" @change="changeOption">
          <option v-for="d in duration" v-bind:value="d.id">{{d.name}}</option>
        </select>
      </div>
      <br />
      <table>
        <tr>
          <th>Modalité</th>
          <th>Bloc</th>
          <th>Note</th>
        </tr>
        <tr v-for="b in block" v-bind:value="b">
          <td>
            {{mode.find(
            x =>
            x.id == b.mode_id
            ).name}}
          </td>
          <td>
            <input type="number" :step="1" :min="0" :max="b.mode_id == 2 ? 3 : 1" v-model="b.block" />
            <button @click="changeBlock(b)">Modifier</button>
          </td>
          <td v-if="b.mode_id == 2 || b.mode_id == 6">
            "{{formation.find(
            x =>
            x.id == b.formation_id
            ).name}}" comprend {{b.block}} bloc(s) de cette modalité
          </td>
          <td v-else-if="b.block == 0">
            "{{formation.find(
            x =>
            x.id == b.formation_id
            ).name}}" comprend PAS cette modalité
          </td>
          <td v-else>
            "{{formation.find(
            x =>
            x.id == b.formation_id
            ).name}}" comprend cette modalité
          </td>
        </tr>
      </table>
    </div>
    <div>
      <h2>Utilisateur</h2>
      <table>
        <tr>
          <th>Utilisateur</th>
          <th>Email</th>
          <th>Rôle</th>
        </tr>
        <tr v-for="u in user" v-bind:value="u">
          <td>{{u.firstname + " " + u.lastname}}</td>
          <td>{{u.email}}</td>
          <td>
            <select v-model="u.role">
              <option v-for="r in role" v-bind:value="r.id">{{r.name}}</option>
            </select>
            <button @click="changeRole(u.id,u.role)">Modifier</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ApiUrl } from "../config";
export default {
  name: "admin",
  data() {
    return {
      // const
      MAX: 999.99,
      MIN: 0,
      STEP: 1,
      // data fetched from database
      avg: 0,
      cpf: 0,
      admin: [],
      formation: [],
      formation_mode: [],
      mode: [],
      user: [],
      // selected option
      s_formation: 0,
      s_duration: 0,
      // blocks
      block: []
    };
  },
  mounted() {
    if (localStorage.role == "admin") console.log("Success");
    else if (
      localStorage.role == "commercial" ||
      localStorage.role == "manager"
    )
      this.$router.push("/client");
    else if (localStorage.role == "user") this.$router.push("/home");
    else this.$router.push("/");
    axios
      .get(ApiUrl + "/admin")
      .then(response => {
        this.admin = response.data.admin;
        this.avg = this.admin.find(obj => obj.key == "avg_price").value;
        this.cpf = this.admin.find(obj => obj.key == "cpf_price").value;
      })
      .catch(error => {
        console.log("Error : " + error);
      });
    axios
      .get(ApiUrl + "/formation")
      .then(response => {
        this.formation = response.data.formation;
      })
      .catch(error => {
        console.log("Error : " + error);
      });
    axios
      .get(ApiUrl + "/formation_mode")
      .then(response => {
        this.formation_mode = response.data.formation_mode;
      })
      .catch(error => {
        console.log("Error : " + error);
      });
    axios
      .get(ApiUrl + "/mode")
      .then(response => {
        this.mode = response.data.mode;
      })
      .catch(error => {
        console.log("Error : " + error);
      });
    axios
      .get(ApiUrl + "/users")
      .then(response => {
        this.user = response.data.user;
      })
      .catch(error => {
        console.log("Error : " + error);
      });
  },
  computed: {
    duration() {
      return [
        { id: "1", name: "3 mois" },
        { id: "2", name: "6 mois" },
        { id: "3", name: "12 mois" }
      ];
    },
    role() {
      return [
        { id: "1", name: "Utilisateur" },
        { id: "2", name: "Commercial" },
        { id: "3", name: "Responsable" },
        { id: "4", name: "Admin" }
      ];
    }
  },
  methods: {
    changeBlock: function(fm) {
      axios
        .put(ApiUrl + "/formation_mode", {
          formation_id: fm.formation_id,
          mode_id: fm.mode_id,
          duration: fm.duration,
          block: fm.block
        })
        .then((_) => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    },
    changeEfficiency: function(id, efficiency) {
      if (efficiency > this.MAX || efficiency < this.MIN) {
        alert("Valeur invalide : " + efficiency);
        return;
      } else {
        efficiency = (efficiency / 1.0).toFixed(2);
      }
      axios
        .put(ApiUrl + "/mode_efficiency", {
          id: id,
          efficiency: efficiency
        })
        .then(response => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    },
    changeHour: function(id, hour) {
      if (hour > this.MAX || hour < this.MIN) {
        alert("Valeur invalide : " + hour);
        return;
      } else {
        hour = (hour / 1.0).toFixed(2);
      }
      axios
        .put(ApiUrl + "/mode_hour", {
          id: id,
          hour: hour
        })
        .then(response => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    },
    changeOption: function() {
      this.block = this.formation_mode.filter(
        obj =>
          obj.formation_id == this.s_formation &&
          obj.duration == this.s_duration
      );
    },
    changePrice: function(id, price) {
      if (price > this.MAX || price < this.MIN) {
        alert("Valeur invalide : " + price);
        return;
      } else {
        price = (price / 1.0).toFixed(2);
      }
      axios
        .put(ApiUrl + "/mode_price", {
          id: id,
          price: price
        })
        .then(response => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    },
    changeRole: function(id, role) {
      axios
        .put(ApiUrl + "/user_role", {
          id: id,
          role: role
        })
        .then(response => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    },
    changeValue: function(key, value) {
      if (value > this.MAX || value < this.MIN) {
        alert("Valeur invalide : " + value);
        return;
      } else {
        value = (value / 1.0).toFixed(2);
      }
      axios
        .put(ApiUrl + "/admin_value", {
          key: key,
          value: value
        })
        .then(response => {
          alert("Mise à jour avec succès");
        })
        .catch(error => {
          console.log("Error : " + error);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.admin {
  height: auto;
  margin: 0 auto;
  padding: 0;
  width: fit-content;
}
h1 {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  margin: 0px auto;
  padding: 0;
  width: fit-content;
}
h2 {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin: 24px auto;
  padding: 0;
  width: fit-content;
}
p {
  color: #000;
  font-size: 24px;
  font-weight: 300;
  margin: 10px 0px;
  padding: 0;
  width: fit-content;
}
select,
input {
  height: 30px;
  margin: 0;
  padding: 0;
  width: fit-content;
}
table {
  text-align: center;
  width: 100%;
}
table,
th,
td {
  border: solid 1px #000;
  border-collapse: collapse;
  margin: 0 auto;
  padding: 10px 10px;
  width: fit-content;
}
button {
  background-color: #79e4f0;
  border: none;
  color: #fff;
  cursor: pointer;
  float: right;
  font-size: 15px;
  font-weight: 300;
  height: 30px;
  margin: 0;
  padding: 0px 10px;
  width: fit-content;
}
</style>
