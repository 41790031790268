<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Créer une simulation</h1>
            <div>
                <h2 class="md-title">1. Client</h2>
                <form novalidate>
                    <div
                        v-if="formError"
                        class="error"
                    >
                        {{ formError }}
                    </div>
                    <md-field>
                        <label>Prénom</label>
                        <md-input
                            v-model="form.customer.firstname"
                            required
                            maxlength="100"
                        />
                    </md-field>
                    <md-field>
                        <label>Nom</label>
                        <md-input
                            v-model="form.customer.lastname"
                            required
                            maxlength="100"
                        />
                    </md-field>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep1"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: 'SimulationCreateStep1',
    data () {
        return {
            form: {
                customer: {
                    firstname: '',
                    lastname: '',
                }
            },
            formError: ''
        };
    },
    methods: {
        ...mapMutations({
            setForm: 'Simulation/setForm'
        }),

        validStep1 () {
            if (this.form.customer.firstname === '' || this.form.customer.lastname === '') {
                this.formError = 'Merci de compléter tous les champs.'
            }

            this.setForm(this.form)
            this.$emit('go-next-step', 2)
        }
    }
};
</script>
