import axios from 'axios'

export default {
    namespaced: true,

    state: {
        elearnings: [],
        elearning: null
    },

    getters: {
        elearnings (state) {
            return state.elearnings
        },
        elearning (state) {
            return state.elearning
        },
    },

    mutations: {
        setElearnings (state, elearnings) {
            state.elearnings = elearnings
        },
        setElearning (state, elearning) {
            state.elearning = elearning
        }
    },

    actions: {
        async getElearnings ({ commit }) {
            try {
                const response = await axios.get('elearnings')
                commit('setElearnings', response.data.elearnings)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les E-Learnings.'
                }
            }
        },
        async getElearning ({ commit }, id) {
            try {
                const response = await axios.get('elearning/' + id)
                commit('setElearning', response.data)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer l\'E-Learning.'
                }
            }
        },
        async create (_, data) {
            try {
                return await axios.post('elearning', data)
            } catch (e) {
                return {
                    error: 'Impossible de créer l\'E-Learning.'
                }
            }
        },
        async update (_, { id, data }) {
            try {
                return await axios.put('elearning/' + id, data)
            } catch (e) {
                return {
                    error: 'Impossible de modifier l\'E-Learning.'
                }
            }
        },
        async delete (_, id) {
            try {
                return await axios.delete('elearning/' + id)
            } catch (e) {
                return {
                    error: 'Impossible de supprimer l\'E-learning.'
                }
            }
        },
        async copy (_, data) {
            try {
                return await axios.post('elearning/copy', data)
            } catch (e) {
                return {
                    error: 'Impossible de copier l\'E-Learning.'
                }
            }
        },
        async checkIfExists (_, data) {
            try {
                return await axios.post('elearning/check-if-exists', data)
            } catch (e) {
                return {
                    error: 'Impossible de savoir si l\'E-learning existe déjà.'
                }
            }
        }
    },
}
