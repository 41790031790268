import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop';
import VueSlider from 'vue-slider-component'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import router from './router'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import { ApiUrl } from './config'
import { VueReCaptcha } from "vue-recaptcha-v3"

Vue.component('VueSlider', VueSlider)

Vue.use(VueDragDrop)
Vue.use(VueMaterial)
Vue.use(VueReCaptcha, {
  siteKey: "6LfRNHEeAAAAALtNdP2bdJOev9-N_FTAXgWDXLnF"
})

require('@/store/subscriber')

axios.defaults.baseURL = ApiUrl

Vue.material.locale.dateFormat = 'dd/MM/yyyy'
Vue.material.locale.firstDayOfAWeek = 1
Vue.material.locale.days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
Vue.material.locale.shortDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
Vue.material.locale.shorterDays = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
Vue.material.locale.months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
Vue.material.locale.shortMonths = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aoû', 'Sept', 'Oct', 'Nov', 'Déc']
Vue.material.locale.shorterMonths = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
Vue.material.locale.cancel = 'Annuler'

Vue.config.productionTip = false

store.dispatch('Auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})


