<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Créer un utilisateur</h1>
            <form novalidate class="md-layout" @submit.prevent="createUser">
                <md-field>
                    <label>Nom</label>
                    <md-input
                        v-model="form.lastname"
                        required
                    ></md-input>
                </md-field>
                <md-field>
                    <label>Prénom</label>
                    <md-input
                        v-model="form.firstname"
                        required
                    ></md-input>
                </md-field>
                <md-field>
                    <label>E-mail</label>
                    <md-input
                        v-model="form.email"
                        required
                    ></md-input>
                </md-field>
                <md-field>
                    <label>Mot de passe</label>
                    <md-input
                        type="password"
                        v-model="form.password"
                        required
                    ></md-input>
                </md-field>
                <md-field>
                    <label>Rôle</label>
                    <md-select
                        v-model="form.role"
                        name="role"
                        id="role"
                        required
                    >
                        <md-option
                            v-for="role in roles"
                            :key="role.id"
                            :value="role.id"
                        >
                            {{ role.name }}
                        </md-option>
                    </md-select>
                </md-field>
                <div
                    v-if="userAlreadyExists"
                    class="error"
                >
                    Le compte lié à cet email existe déjà.
                </div>
                <div class="actions-wrapper">
                    <md-button
                        type="submit"
                        class="md-raised md-primary"
                        :disabled="disabled"
                    >
                        Créer
                    </md-button>
                </div>
            </form>
        </div>
        <md-dialog-alert
            :md-active.sync="success"
            md-title="Utilisateur créé !"
            :md-content="`L'utilisateur ${form.firstname} ${form.lastname} a bien été créé.`"
            md-confirm-text="Ok"
            @md-closed="$router.push({ name: 'UserList' })"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>
import { mapActions } from "vuex";

    export default {
        name: "UserCreate",
        data() {
            return {
                success: false,
                alert: false,
                disabled: false,
                error: '',
                form: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: '',
                    role: ''
                },
                roles: [
                    {
                        id: 3,
                        name: "Commercial",
                    },
                    {
                        id: 4,
                        name: "Admin",
                    },
                ],
                userAlreadyExists: false
            }
        },
        methods: {
            ...mapActions({
                create: 'User/create',
                checkIfExists: 'User/checkIfExists'
            }),
            createUser () {
                this.checkIfExists({
                    email: this.form.email
                }).then((response) => {
                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {
                        this.userAlreadyExists = response.data.userAlreadyExists
                        if (!this.userAlreadyExists) {
                            this.disabled = true

                            this.create(this.form).then((response) => {
                                this.disabled = false

                                if (response.error) {
                                    this.error = response.error
                                    this.alert = true
                                } else {
                                    this.success = true
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
