<template>
  <div>
        <SimulationCreateStep1
            v-if="step === 1"
            @go-next-step="step = $event"
        />
       <SimulationCreateStep2
           v-if="step === 2"
           @go-next-step="step = $event"
           @error="showError"
       />
       <SimulationCreateStep3
           v-if="step === 3"
           @go-next-step="step = $event"
           @error="showError"
       />
      <md-dialog-alert
          :md-active.sync="alert"
          md-title="Une erreur est survenue"
          :md-content="error"
          md-confirm-text="Ok"
      />
  </div>
</template>

<script>
import SimulationCreateStep1 from "./SimulationCreateStep1"
import SimulationCreateStep2 from "./SimulationCreateStep2"
import SimulationCreateStep3 from "./SimulationCreateStep3"
import {mapActions} from "vuex";

export default {
    name: 'SimulationCreate',
    components: {
        SimulationCreateStep1,
        SimulationCreateStep2,
        SimulationCreateStep3
  },
  data () {
      return {
          step: 1,
          error: '',
          success: false,
          alert: false
      };
  },
    created () {
        this.resetForm()
    },
    methods: {
        ...mapActions({
            resetForm: 'Simulation/resetForm'
        }),
        showError (error) {
            this.error = error
            this.alert = true
        }
    }
};
</script>
