<template>
  <div class="login">
    <h1 class="md-title">
      Simulateur de parcours
      <br />Move Up ! Formation
    </h1>
    <form
        class="md-layout"
        @submit.prevent="login"
    >
        <div
            v-if="authError"
            class="error"
        >
            {{ authError }}
        </div>
      <md-field md-clearable>
        <md-input type="email" required placeholder="Email" v-model="form.email" />
      </md-field>
      <md-field>
        <md-input type="password" required placeholder="Mot de passe" v-model="form.password" />
      </md-field>
        <div class="md-layout-item md-size-100">
            <router-link :to="{ 'name': 'PasswordResetSendEmail' }"><p>Mot de passe oublié ?</p></router-link>
        </div>
        <div class="md-layout-item md-layout md-alignment-center-center md-size-100">
            <md-button type="submit" class="md-primary required md-raised md-dense">CONNEXION</md-button>
        </div>
    </form>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Login',
    data: function () {
        return {
            form: {
                email: '',
                password: '',
                recaptcha: '',
            }
        };
    },
    computed: {
        ...mapGetters({
            authError: 'Auth/error'
        })
    },
    methods: {
        ...mapActions({
            authLogin: 'Auth/login'
        }),
        async login () {
            this.form.recaptcha = await this.$recaptcha('login')

            this.authLogin(this.form).then(() => {
                this.$router.push({ name: 'SimulationList' })
            })
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .login {
    & > * {
      width: 60%;
      margin-left: 20%;
      margin-bottom: 3rem;
    }
    form {

        .error {
            color: #ff5252;
        }

      .md-field {
        margin-bottom: 2rem;
      }

        .md-button {
            width: 300px;
        }
    }
  }
</style>
