<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Créer une matière</h1>
            <div v-if="step === 1">
                <h2 class="md-title">1. Définir le titre de la matière</h2>
                <form novalidate>
                    <md-field>
                        <label>Matière</label>
                        <md-input
                            v-model="form.name"
                            required
                            maxlength="100"
                        />
                    </md-field>
                    <div
                        v-if="trainingAlreadyExists"
                        class="error"
                    >
                        Cette matière existe déjà.
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            v-if="form.name"
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep1"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 2">
                <h2 class="md-title">2. Définir les modes disponibles</h2>
                <form novalidate>
                    <div class="checkbox-wrapper">
                        <md-checkbox
                            v-for="mode in modes"
                            :key="mode.id"
                            v-model="form.modes"
                            :value="mode"
                            @change="updateData($event, mode)"
                        >
                            {{ mode.name }}
                        </md-checkbox>
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 1"
                        >
                            Retour
                        </md-button>
                        <md-button
                            v-if="form.modes.length > 0"
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep2"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 3">
                <h2 class="md-title">3. Définir le tarif pour chaque mode</h2>
                <form novalidate>
                    <md-field
                        v-for="mode in form.modes"
                        :key="mode.id"
                    >
                        <label>{{ mode.name }}
                            <span v-if="mode.id === 1">(Prix unitaire €HT par heure)</span>
                        </label>
                        <md-input
                            v-if="mode.id === 1"
                            type="number"
                            step="1"
                            min="1"
                            v-model="mode.price"
                        />
                        <multiselect
                            v-else-if="mode.id === 3"
                            v-model="modeElearnings"
                            :options="elearnings"
                            :multiple="true"
                            label="name"
                            track-by="name"
                            placeholder="Sélectionnez..."
                        >
                        </multiselect>
                        <multiselect
                            v-else
                            v-model="modeExams"
                            :options="exams"
                            :multiple="true"
                            label="name"
                            track-by="name"
                            :showLabels="false"
                            placeholder="Sélectionnez..."
                        >
                        </multiselect>
                    </md-field>
                    <div
                        v-if="modePriceError"
                        class="error"
                    >
                        {{ modePriceError }}
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 2"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep3"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 4">
                <h2 class="md-title">4. Définir l'intitulé des niveaux</h2>
                <form novalidate>
                    <md-field
                        v-for="(level, index) in form.levels"
                        :key="index"
                    >
                        <label>{{ level.label }}</label>
                        <md-input
                            type="text"
                            v-model="level.value"
                        />
                    </md-field>
                    <md-button
                        type="button"
                        class="md-raised md-primary"
                        @click="insertLevel()"
                    >
                        Ajouter un niveau
                    </md-button>
                    <md-button
                        v-if="form.levels.length > 0"
                        type="button"
                        class="md-raised md-accent"
                        @click="deleteLevel()"
                    >
                        Supprimer un niveau
                    </md-button>
                    <br/>
                    <div
                        v-if="levelNameError"
                        class="error"
                    >
                        {{ levelNameError }}
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 3"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            @click="validStep4"
                        >
                            Continuer
                        </md-button>
                    </div>
                </form>
            </div>
            <div v-else-if="step === 5">
                <h2 class="md-title">5. Définir la matrice de progression</h2>
                <form novalidate>
                    <div
                        v-for="mode in form.modes"
                        :key="mode.id"
                    >
                        <div v-if="mode.id === 1">
                            <md-table>
                                <md-table-row>
                                    <md-table-head>Niveaux</md-table-head>
                                    <md-table-head>Nombre d'heures pour {{ mode.name }}</md-table-head>
                                </md-table-row>
                                <md-table-row
                                    v-for="(nbHour, index) in mode.nbHours"
                                    :key="index"
                                >
                                    <md-table-cell>
                                        Du niveau {{ nbHour.startLevel }}
                                        <br/>
                                        au niveau {{ nbHour.endLevel }}
                                    </md-table-cell>
                                    <md-table-cell>
                                        <md-field>
                                            <md-input
                                                type="number"
                                                step="1"
                                                min="1"
                                                v-model="nbHour.quantity"
                                            />
                                        </md-field>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>
                    </div>
                    <div class="actions-wrapper">
                        <md-button
                            type="button"
                            class="md-dense md-primary"
                            @click="step = 4"
                        >
                            Retour
                        </md-button>
                        <md-button
                            type="button"
                            class="md-dense md-raised md-primary"
                            :disabled="disabled"
                            @click="createTraining"
                        >
                            Créer
                        </md-button>
                    </div>
                </form>
            </div>
            <md-dialog-alert
                :md-active.sync="success"
                md-title="Matière créée !"
                :md-content="`La matière ${form.name} a bien été créée.`"
                md-confirm-text="Ok"
                @md-closed="$router.push({ name: 'TrainingList' })"
            />
            <md-dialog-alert
                :md-active.sync="alert"
                md-title="Une erreur est survenue"
                :md-content="error"
                md-confirm-text="Ok"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import Multiselect from 'vue-multiselect'

export default {
    name: 'TrainingCreate',
    components: {
        Multiselect
    },
    data() {
        return {
            success: false,
            alert: false,
            disabled: false,
            error: '',
            step: 1,
            form: {
                name: '',
                modes: [],
                levels: [
                    {
                        label: '1er niveau',
                        value: ''
                    }
                ]
            },
            modeElearnings: [],
            modeExams: [],
            modePriceError: '',
            levelNameError: '',
            trainingAlreadyExists: false
        };
    },
    created () {
        this.getModes().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getExams().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })

        this.getElearnings().then((response) => {
            if (response.error) {
                this.error = response.error
                this.alert = true
            }
        })
    },
    computed: {
        ...mapGetters({
            modes: 'Mode/modes',
            exams: 'Exam/exams',
            elearnings: 'Elearning/elearnings'
        }),
    },
    methods: {
        ...mapActions({
            getModes: 'Mode/getModes',
            getExams: 'Exam/getExams',
            getElearnings: 'Elearning/getElearnings',
            create: 'Training/create',
            checkIfExists: 'Training/checkIfExists'
        }),
        validStep1 () {
            this.checkIfExists({
                name: this.form.name
            }).then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.trainingAlreadyExists = response.data.trainingAlreadyExists
                    if (!this.trainingAlreadyExists) {
                        this.step++
                    }
                }
            })
        },
        updateData ($event, mode) {
            if (mode.id === 1 && $event.find(item => item.name === mode.name) === undefined) {
                this.form.levels = []
                this.form.levels.push({
                    label: '1er niveau',
                    value: ''
                })
                mode.price = ''
                mode.nbHours = []
            } else if (mode.id === 3 && $event.find(item => item.name === mode.name) === undefined) {
                this.modeElearnings = []
                mode.elearnings = []
            } else if (mode.id === 4 && $event.find(item => item.name === mode.name) === undefined) {
                this.modeExams = []
                mode.exams = []
            }
        },
        validStep2 () {
            this.form.modes = this.form.modes.map(function (mode) {

                if (mode.id === 1) {
                    if (!mode.hasOwnProperty('price')) {
                        mode.price = ''
                    }
                } else if (mode.id === 3) {
                    if (!mode.hasOwnProperty('elearnings')) {
                        mode.elearnings = []
                    }
                } else if (mode.id === 4) {
                    if (!mode.hasOwnProperty('exams')) {
                        mode.exams = []
                    }
                }

                return mode
            })
            this.step++
        },
        validStep3 () {
            this.modePriceError = ''

            const individualMode = this.form.modes.find(mode => mode.id === 1)
            const elearningMode = this.form.modes.find(mode => mode.id === 3)
            const examMode = this.form.modes.find(mode => mode.id === 4)
            let nbErrors = 0

            if (individualMode !== undefined) {
                if (individualMode.price === '') {
                    nbErrors++
                }
            }

            if (elearningMode !== undefined) {
                if (this.modeElearnings.length === 0) {
                    nbErrors++
                }
            }

            if (examMode !== undefined) {
                if (this.modeExams.length === 0) {
                    nbErrors++
                }
            }

            if (nbErrors > 0) {
                this.modePriceError = 'Vous devez renseigner tous les champs.'
                return
            }

            if (elearningMode !== undefined) {
                elearningMode.elearnings = this.modeElearnings
            }

            if (examMode !== undefined) {
                examMode.exams = this.modeExams
            }

            if (individualMode === undefined) {
                this.createTraining()
            } else {
                this.step++
            }
        },
        insertLevel () {
            this.form.levels.push({
                label: this.form.levels.length + 1 + 'e niveau',
                value: ''
            })
        },
        deleteLevel () {
            this.form.levels.pop()
        },
        validStep4 () {
            this.levelNameError = ''
            let nbErrors = 0

            for (const level of this.form.levels) {
                if (level.value === '') {
                    nbErrors++
                }
            }

            if (nbErrors > 0) {
                this.levelNameError = 'Vous devez renseigner tous les champs.'
                return
            }

            const formLevels = this.form.levels

            this.form.modes = this.form.modes.map(function (mode) {

                if (mode.id === 1) {
                    mode.nbHours = []

                    for (let i = 0; i < formLevels.length; i++) {
                        if (formLevels[i + 1] !== undefined) {
                            const level = formLevels[i]

                            mode.nbHours.push({
                                startLevel: level.value,
                                endLevel: formLevels[i + 1].value,
                                quantity: 0
                            })
                        }
                    }
                }

                return mode
            })
            this.step++
        },
        createTraining () {
            this.disabled = true

            this.create(this.form).then((response) => {
                this.disabled = false

                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.success = true
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>

.checkbox-wrapper {
    display: flex;
    flex-direction: column;
}
</style>
