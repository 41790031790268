<template>
    <div>
        <div style="text-align: center; width: 100%">
            <h1>E-Learning</h1>
        </div>
        <md-table
            class="list"
            v-model="searched"
            md-sort="id"
            md-sort-order="asc"
            md-fixed-header
        >
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <md-button
                        class="md-raised md-primary"
                        :to="{ name: 'ElearningCreate' }"
                    >
                        Créer E-Learning
                    </md-button>
                </div>
                <md-field md-clearable class="md-toolbar-section-end">
                    <md-input
                        placeholder="Rechercher par intitulé..."
                        v-model="search"
                        @input="searchOnTable"
                    />
                </md-field>
            </md-table-toolbar>
            <md-table-empty-state
                md-label="Pas d'E-Learning trouvé"
                :md-description="`Aucun E-Learning trouvé pour cette requête : '${search}'.`">
            </md-table-empty-state>
            <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
            >
                <md-table-cell
                    md-label="ID"
                    md-sort-by="id"
                >
                    {{ item.id }}
                </md-table-cell>
                <md-table-cell
                    md-label="E-Learning"
                    md-sort-by="e-learning"
                >
                    {{ item.name }}
                </md-table-cell>
                <md-table-cell
                    md-label="Prix (€)"
                    md-sort-by="price"
                >
                    {{ item.price }}
                </md-table-cell>
                <md-table-cell>
                    <a class="cell-option"
                       title="Modifier"
                       @click="onUpdate(item.id)"
                    >
                        <md-icon>edit</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Copier"
                       @click="onCopy(item.id)"
                    >
                        <md-icon>file_copy</md-icon>
                    </a>
                    <a class="cell-option"
                       title="Supprimer"
                       @click="onDelete(item.id)"
                    >
                        <md-icon>delete</md-icon>
                    </a>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <md-dialog-confirm
            :md-active.sync="elearningToDelete"
            md-title="Supprimer l'E-Learning"
            md-content="Voulez-vous supprimer cet E-Learning ?"
            md-confirm-text="Oui"
            md-cancel-text="Non"
            @md-confirm="deleteElearning"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
        <md-dialog-prompt
            :md-active.sync="elearningToCopy"
            v-model="nbCopy"
            md-title="Copier l'E-Learning"
            md-input-placeholder="Indiquez le nombre de copies..."
            md-confirm-text="Copier"
            md-cancel-text="Annuler"
            @md-confirm="copyElearning"
        />
    </div>
</template>

<script>


import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ElearningList",
        data () {
            return {
                nbCopy: 0,
                search: '',
                searched: [],
                elearningToDelete: null,
                elearningToCopy: null,
                alert: false,
                error: '',
            }
        },
        created () {
            this.getElearnings().then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                } else {
                    this.searched = this.elearnings
                }
            })
        },
        computed: {
            ...mapGetters({
                elearnings: 'Elearning/elearnings'
            })
        },
        methods: {
            ...mapActions({
                getElearnings: 'Elearning/getElearnings',
                delete: 'Elearning/delete',
                copy: 'Elearning/copy'
            }),
            searchOnTable () {
                this.searched = this.searchByName(this.elearnings, this.search)
            },
            searchByName (items, term) {
                if (term) {
                    return items.filter(item => this.toLower(item.name).includes(this.toLower(term)))
                }

                return items
            },
            toLower (text) {
                return text.toString().toLowerCase()
            },
            onUpdate (id) {
                this.$router.push({ name: 'ElearningUpdate', params: { id: id } })
            },
            onDelete (id) {
                this.elearningToDelete = id
            },
            deleteElearning () {
                this.delete(this.elearningToDelete).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getElearnings().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.elearnings
                        }
                    })
                })
            },
            onCopy (id) {
                this.elearningToCopy = id
            },
            copyElearning () {
                this.copy({
                    id: this.elearningToCopy,
                    nbCopy: this.nbCopy
                }).then((response) => {

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                        return
                    }

                    this.getElearnings().then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.searched = this.elearnings
                        }
                    })
                })
            }
        }
    }
</script>
