import axios from 'axios'

export default {
    namespaced: true,

    state: {
        trainings: [],
        training: null,
        exams: [],
        elearnings: [],
        modes: [],
        levels: [],
        matrixes: []
    },

    getters: {
        trainings (state) {
            return state.trainings
        },
        training (state) {
            return state.training
        },
        exams (state) {
            return state.exams
        },
        elearnings (state) {
            return state.elearnings
        },
        modes (state) {
            return state.modes
        },
        levels (state) {
            return state.levels
        },
        matrixes (state) {
            return state.matrixes
        },
    },

    mutations: {
        setTrainings (state, trainings) {
            state.trainings = trainings
        },
        setTraining (state, training) {
            state.training = training
        },
        setExams (state, exams) {
            state.exams = exams
        },
        setElearnings (state, elearnings) {
            state.elearnings = elearnings
        },
        setModes (state, modes) {
            state.modes = modes
        },
        setLevels (state, levels) {
            state.levels = levels
        },
        setMatrixes (state, matrixes) {
            state.matrixes = matrixes
        }
    },

    actions: {
        async getTrainings ({ commit }) {
            try {
                const response = await axios.get('trainings')
                commit('setTrainings', response.data.trainings)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les matières.'
                }
            }
        },
        async getTraining ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id)
                commit('setTraining', response.data)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer la matière.'
                }
            }
        },
        async getExams ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id + '/exams')
                commit('setExams', response.data.trainingExams)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les examens de la matière.'
                }
            }
        },
        async getElearnings ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id + '/elearnings')
                commit('setElearnings', response.data.trainingElearnings)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les e-learnings de la matière.'
                }
            }
        },
        async getModes ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id + '/modes')
                commit('setModes', response.data.trainingModes)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les modes de la matière.'
                }
            }
        },
        async getLevels ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id + '/levels')
                commit('setLevels', response.data.trainingLevels)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les niveaux de la matière.'
                }
            }
        },
        async getMatrixes ({ commit }, id) {
            try {
                const response = await axios.get('training/' + id + '/matrixes')
                commit('setMatrixes', response.data.trainingMatrixes)
                return response.data
            } catch (e) {
                return {
                    error: 'Impossible de récupérer les matrices de la matière.'
                }
            }
        },
        async create (_, data) {
            try {
                return await axios.post('training', data)
            } catch (e) {
                return {
                    error: 'Impossible de créer la matière.'
                }
            }
        },
        async update (_, { id, data }) {
            try {
                return await axios.put('training/' + id, data)
            } catch (e) {
                return {
                    error: 'Impossible de modifier la matière.'
                }
            }
        },
        async delete (_, id) {
            try {
                return await axios.delete('training/' + id)
            } catch (e) {
                return {
                    error: 'Impossible de supprimer la matière.'
                }
            }
        },
        async copy (_, data) {
            try {
                return await axios.post('training/copy', data)
            } catch (e) {
                return {
                    error: 'Impossible de copier la matière.'
                }
            }
        },
        async checkIfExists (_, data) {
            try {
                return await axios.post('training/check-if-exists', data)
            } catch (e) {
                return {
                    error: 'Impossible de savoir si la matière existe déjà.'
                }
            }
        }
    },
}
