<template>
    <div class="form-wrapper">
        <div class="container">
            <h1>Modifier un E-learning</h1>
            <form novalidate class="md-layout" @submit.prevent="updateElearning">
                <md-field>
                    <label>E-Learning</label>
                    <md-input
                        v-model="form.name"
                        required
                    />
                </md-field>
                <md-field>
                    <label>Prix</label>
                    <md-input
                        v-model="form.price"
                        type="number"
                        required
                    />
                </md-field>
                <div
                    v-if="elearningAlreadyExists"
                    class="error"
                >
                    Cet E-learning existe déjà.
                </div>
                <div class="actions-wrapper">
                    <md-button
                        type="submit"
                        class="md-dense md-raised md-primary"
                        :disabled="disabled"
                    >
                        Modifier
                    </md-button>
                </div>
            </form>
        </div>
        <md-dialog-alert
            :md-active.sync="success"
            md-title="E-Learning modifié !"
            :md-content="`L'E-Learning ${form.name} a bien été modifié.`"
            md-confirm-text="Ok"
            @md-closed="$router.push({ name: 'ElearningList' })"
        />
        <md-dialog-alert
            :md-active.sync="alert"
            md-title="Une erreur est survenue"
            :md-content="error"
            md-confirm-text="Ok"
        />
    </div>
</template>

<script>


import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ElearningUpdate",
        props: {
            id: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                success: false,
                alert: false,
                disabled: false,
                error: '',
                form: {
                    name: '',
                    price: 0,
                },
                elearningAlreadyExists: false
            }
        },
        created () {
            this.getElearning(this.id).then((response) => {
                if (response.error) {
                    this.error = response.error
                    this.alert = true
                    return
                }

                const elearning = response
                for (const property in elearning) {
                    if (this.form.hasOwnProperty(property)) {
                        this.form[property] = elearning[property]
                    }
                }
            })
        },
        computed: {
            ...mapGetters({
                elearning: 'Elearning/elearning'
            })
        },
        methods: {
            ...mapActions({
                getElearning: 'Elearning/getElearning',
                update: 'Elearning/update',
                checkIfExists: 'Elearning/checkIfExists'
            }),

            updateElearning () {
                if (this.elearning.name !== this.form.name) {
                    this.checkIfExists({
                        name: this.form.name
                    }).then((response) => {
                        if (response.error) {
                            this.error = response.error
                            this.alert = true
                        } else {
                            this.elearningAlreadyExists = response.data.elearningAlreadyExists
                            if (!this.elearningAlreadyExists) {
                                this.callUpdate()
                            }
                        }
                    })
                } else {
                    this.callUpdate()
                }
            },

            callUpdate () {
                this.disabled = true

                this.update({ id: this.id, data: this.form }).then((response) => {
                    this.disabled = false

                    if (response.error) {
                        this.error = response.error
                        this.alert = true
                    } else {

                        this.success = true
                    }
                })
            }
        }
    }
</script>
