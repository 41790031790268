import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../components/Login";
import PasswordResetSendEmail from '../components/PasswordResetSendEmail'
import PasswordResetForm from '../components/PasswordResetForm'

import SimulationList from "../components/SimulationList"
import SimulationShow from "../components/SimulationShow"
import SimulationCreate from "../components/SimulationCreate"

import TrainingList from "../components/TrainingList"
import TrainingShow from "../components/TrainingShow"
import TrainingCreate from "../components/TrainingCreate"
import TrainingUpdate from "../components/TrainingUpdate"

import ExamList from "../components/ExamList"
import ExamCreate from "../components/ExamCreate"
import ExamUpdate from "../components/ExamUpdate"

import ElearningList from "../components/ElearningList"
import ElearningCreate from "../components/ElearningCreate"
import ElearningUpdate from "../components/ElearningUpdate"

import UserList from '../components/UserList'
import UserCreate from '../components/UserCreate'
import UserUpdate from '../components/UserUpdate'

import admin from "../components/admin";

import store from '@/store'

Vue.use(VueRouter)

const beforeEnterForPrivateRoutes = function (to, from, next) {
    if (!store.getters['Auth/authenticated']) {
        next({ name: 'Login' })
    }

    const authUser = store.getters['Auth/user']
    if (to.name !== 'SimulationList' && authUser.role !== 4) {
        next({ name: 'SimulationList' })
    }

    next()
};

const beforeEnterForPublicRoutes = function (to, from, next) {
    if (store.getters['Auth/authenticated']) {
        next({ name: 'SimulationList' })
    }

    next()
};

const routes = [
    {
        path: '/',
        component: Login,
        name: 'Login',
        beforeEnter: beforeEnterForPublicRoutes
    },
    {
        path: '/password/reset',
        component: PasswordResetSendEmail,
        name: 'PasswordResetSendEmail',
        beforeEnter: beforeEnterForPublicRoutes
    },
    {
        path: '/password/reset/:token',
        component: PasswordResetForm,
        name: 'PasswordResetForm',
        props: true,
        beforeEnter: beforeEnterForPublicRoutes
    },
    {
        path: '/simulations',
        component: SimulationList,
        name: 'SimulationList',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/simulation/create',
        component: SimulationCreate,
        name: 'SimulationCreate',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/simulation/:id',
        component: SimulationShow,
        name: 'SimulationShow',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/trainings',
        component: TrainingList,
        name: 'TrainingList',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/training/create',
        component: TrainingCreate,
        name: 'TrainingCreate',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/training/update/:id',
        component: TrainingUpdate,
        name: 'TrainingUpdate',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/training/:id',
        component: TrainingShow,
        name: 'TrainingShow',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/exams',
        component: ExamList,
        name: 'ExamList',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/exam/create',
        component: ExamCreate,
        name: 'ExamCreate',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/exam/update/:id',
        component: ExamUpdate,
        name: 'ExamUpdate',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/elearnings',
        component: ElearningList,
        name: 'ElearningList',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/elearning/create',
        component: ElearningCreate,
        name: 'ElearningCreate',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/elearning/update/:id',
        component: ElearningUpdate,
        name: 'ElearningUpdate',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/users',
        component: UserList,
        name: 'UserList',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/user/create',
        component: UserCreate,
        name: 'UserCreate',
        beforeEnter: beforeEnterForPrivateRoutes
    },
    {
        path: '/user/update/:id',
        component: UserUpdate,
        name: 'UserUpdate',
        props: true,
        beforeEnter: beforeEnterForPrivateRoutes
    },


    { path: '/admin', component: admin, beforeEnter: beforeEnterForPrivateRoutes },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router
